import { css } from 'styled-components';

const headingStyles = css`
  /* Heading Styles */
  font-weight: bold;

  /* h1 */
  h1 {
    font-size: 64px;
    line-height: 80px;
    letter-spacing: -1.43px;
  }

  /* h2 */
  h2 {
    font-size: 48px;
    line-height: 62px;
    letter-spacing: -1.07px;
  }

  /* h3 */
  h3 {
    font-size: 40px;
    line-height: 52px;
    letter-spacing: -0.89px;
  }

  /* h4 */
  h4 {
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -0.69px;
  }

  /* h5 */
  h5 {
  }

  /* h6 */
  h6 {
  }
`;

export { headingStyles };
