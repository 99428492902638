import * as React from 'react';
import { AppProps } from 'next/app';
import styled, {
  ThemeProvider as StyledThemeProvider,
} from 'styled-components';
import { MantineProvider } from '@mantine/core';
import { ThemeProvider } from 'next-themes';
import { NotificationsProvider } from '@mantine/notifications';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { WagmiConfig } from 'wagmi';
import { ModalsProvider } from '@mantine/modals';

import { GlobalStyles } from '../components/common/styles/GlobalStyle';

import { lightTheme } from '../theme';
import AdminLayout from '../components/layout/AdminLayout';
import { emotionCache } from '../utils/emotion-cache';
import AuthGate from '../components/AuthGate';

import { appInfo, wagmiClient, configuredChains } from 'common/rainbowkit';
import { Mixpanel } from '../utils/mixpanel';

const AppWrapper = styled.main``;

export default function App({ Component, pageProps, router }: AppProps) {
  const [queryClient] = React.useState(() => new QueryClient());
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    const handleRouteChange = (url: string) => {
      Mixpanel.track(document.title, { url, title: document.title });
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!mounted) return null;

  return (
    <>
      <ThemeProvider defaultTheme="light" enableSystem={false}>
        <StyledThemeProvider theme={lightTheme}>
          <GlobalStyles />
          <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider appInfo={appInfo} chains={configuredChains}>
              <MantineProvider emotionCache={emotionCache}>
                <NotificationsProvider
                  position="top-right"
                  zIndex={2077}
                  limit={5}
                >
                  <ModalsProvider>
                    <QueryClientProvider client={queryClient}>
                      <Hydrate state={pageProps.dehydratedState}>
                        <AppWrapper>
                          <AuthGate />
                          {router.pathname === '/' ? (
                            <Component {...pageProps} />
                          ) : (
                            <AdminLayout>
                              <Component {...pageProps} />
                              <TawkMessengerReact
                                propertyId={
                                  process.env
                                    .NEXT_PUBLIC_TAWK_MESSENGER_PROPERTY_ID
                                }
                                widgetId={
                                  process.env
                                    .NEXT_PUBLIC_TAWK_MESSENGER_WIDGET_ID
                                }
                              />
                            </AdminLayout>
                          )}
                        </AppWrapper>
                      </Hydrate>
                      <ReactQueryDevtools />
                    </QueryClientProvider>
                  </ModalsProvider>
                </NotificationsProvider>
              </MantineProvider>
            </RainbowKitProvider>
          </WagmiConfig>
        </StyledThemeProvider>
      </ThemeProvider>
    </>
  );
}
