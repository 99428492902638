import { css } from 'styled-components';

const cssVariables = css`
  :root {
    /* Font Families */
    --primary-font-family: ${({ theme }) => theme.fontFamily.primary};
    /* Color Palette */
    --main-background-color: ${({ theme }) => theme.background['100']};
    --main-text-color: ${({ theme }) => theme.text['100']};
  }
`;

export { cssVariables };
