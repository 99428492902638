import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  jsonb: any;
  timestamp: any;
};

export type AccessToken = {
  __typename?: 'AccessToken';
  access_token?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type ClaimAirdropInput = {
  accountAddress: Scalars['String'];
  airdropId: Scalars['Int'];
};

export type ClaimAirdropOutput = {
  __typename?: 'ClaimAirdropOutput';
  abi: Scalars['String'];
  accountAddress: Scalars['String'];
  amount: Scalars['String'];
  bytecode: Scalars['String'];
  proof: Array<Maybe<Scalars['String']>>;
};

export type CreateAirdropInput = {
  brandColor: Scalars['String'];
  chainId: Scalars['Int'];
  coverURL: Scalars['String'];
  endDate: Scalars['String'];
  listId?: InputMaybe<Scalars['Int']>;
  logoURL: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  startDate: Scalars['String'];
  tokenAddress: Scalars['String'];
  tokensAvailable: Scalars['String'];
  usersData: Array<UserDataInput>;
};

export type CreateAirdropOutput = {
  __typename?: 'CreateAirdropOutput';
  abi: Scalars['String'];
  airdropId: Scalars['Int'];
  bytecode: Scalars['String'];
};

export type CreateErc721AirdropInput = {
  brandColor: Scalars['String'];
  chainId: Scalars['Int'];
  coverURL: Scalars['String'];
  endDate: Scalars['String'];
  listId?: InputMaybe<Scalars['Int']>;
  logoURL: Scalars['String'];
  name: Scalars['String'];
  nftImageURL: Scalars['String'];
  slug: Scalars['String'];
  startDate: Scalars['String'];
  tokenName: Scalars['String'];
  tokenSymbol: Scalars['String'];
  tokensAvailable: Scalars['String'];
  usersData: Array<UserDataInput>;
};

export type CreateErc721AirdropOutput = {
  __typename?: 'CreateERC721AirdropOutput';
  abi: Scalars['String'];
  airdropId: Scalars['Int'];
  bytecode: Scalars['String'];
};

export type CreateErc1155AirdropInput = {
  brandColor: Scalars['String'];
  chainId: Scalars['Int'];
  coverURL: Scalars['String'];
  endDate: Scalars['String'];
  listId?: InputMaybe<Scalars['Int']>;
  logoURL: Scalars['String'];
  name: Scalars['String'];
  nftImageURL: Scalars['String'];
  slug: Scalars['String'];
  startDate: Scalars['String'];
  tokenName: Scalars['String'];
  tokenSymbol: Scalars['String'];
  tokensAvailable: Scalars['String'];
  usersData: Array<UserDataInput>;
};

export type CreateErc1155AirdropOutput = {
  __typename?: 'CreateERC1155AirdropOutput';
  abi: Scalars['String'];
  airdropId: Scalars['Int'];
  bytecode: Scalars['String'];
};

export type CreateJwtTokenInput = {
  message: Scalars['String'];
  sig_r: Scalars['String'];
  sig_s: Scalars['String'];
  sig_v: Scalars['String'];
};

export type CreateNativeAirdropInput = {
  brandColor: Scalars['String'];
  chainId: Scalars['Int'];
  coverURL: Scalars['String'];
  endDate: Scalars['String'];
  logoURL: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  startDate: Scalars['String'];
  usersData: Array<UserDataInput>;
};

export type CreateUnclaimableAirdropInput = {
  chainId: Scalars['Int'];
  name: Scalars['String'];
  ownerAddress: Scalars['String'];
  tokenAddress: Scalars['String'];
  tokenType: Scalars['String'];
  usersData: Array<UnclaimableAirdropUserData>;
};

export type CreateUnclaimableAirdropOutput = {
  __typename?: 'CreateUnclaimableAirdropOutput';
  abi: Scalars['String'];
  calldata: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type ParseCsvInput = {
  csvData: Scalars['String'];
  tokenType: Scalars['String'];
};

export type SavedListInput = {
  listId: Scalars['Int'];
};

export type SavedListOutput = {
  __typename?: 'SavedListOutput';
  fileUrl: Scalars['String'];
};

export type SignedMessage = {
  __typename?: 'SignedMessage';
  message: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "UnclaimableAirdrop" */
export type UnclaimableAirdrop = {
  __typename?: 'UnclaimableAirdrop';
  /** An object relationship */
  chain: Chains;
  chainId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  tokenAddress: Scalars['String'];
  type: Scalars['String'];
};

export type UnclaimableAirdropUserData = {
  address: Scalars['String'];
  amount: Scalars['String'];
  tokenID: Scalars['String'];
};

/** aggregated selection of "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Aggregate = {
  __typename?: 'UnclaimableAirdrop_aggregate';
  aggregate?: Maybe<UnclaimableAirdrop_Aggregate_Fields>;
  nodes: Array<UnclaimableAirdrop>;
};

/** aggregate fields of "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Aggregate_Fields = {
  __typename?: 'UnclaimableAirdrop_aggregate_fields';
  avg?: Maybe<UnclaimableAirdrop_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<UnclaimableAirdrop_Max_Fields>;
  min?: Maybe<UnclaimableAirdrop_Min_Fields>;
  stddev?: Maybe<UnclaimableAirdrop_Stddev_Fields>;
  stddev_pop?: Maybe<UnclaimableAirdrop_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UnclaimableAirdrop_Stddev_Samp_Fields>;
  sum?: Maybe<UnclaimableAirdrop_Sum_Fields>;
  var_pop?: Maybe<UnclaimableAirdrop_Var_Pop_Fields>;
  var_samp?: Maybe<UnclaimableAirdrop_Var_Samp_Fields>;
  variance?: Maybe<UnclaimableAirdrop_Variance_Fields>;
};


/** aggregate fields of "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UnclaimableAirdrop_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Aggregate_Order_By = {
  avg?: InputMaybe<UnclaimableAirdrop_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<UnclaimableAirdrop_Max_Order_By>;
  min?: InputMaybe<UnclaimableAirdrop_Min_Order_By>;
  stddev?: InputMaybe<UnclaimableAirdrop_Stddev_Order_By>;
  stddev_pop?: InputMaybe<UnclaimableAirdrop_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<UnclaimableAirdrop_Stddev_Samp_Order_By>;
  sum?: InputMaybe<UnclaimableAirdrop_Sum_Order_By>;
  var_pop?: InputMaybe<UnclaimableAirdrop_Var_Pop_Order_By>;
  var_samp?: InputMaybe<UnclaimableAirdrop_Var_Samp_Order_By>;
  variance?: InputMaybe<UnclaimableAirdrop_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Arr_Rel_Insert_Input = {
  data: Array<UnclaimableAirdrop_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<UnclaimableAirdrop_On_Conflict>;
};

/** aggregate avg on columns */
export type UnclaimableAirdrop_Avg_Fields = {
  __typename?: 'UnclaimableAirdrop_avg_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Avg_Order_By = {
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "UnclaimableAirdrop". All fields are combined with a logical 'AND'. */
export type UnclaimableAirdrop_Bool_Exp = {
  _and?: InputMaybe<Array<UnclaimableAirdrop_Bool_Exp>>;
  _not?: InputMaybe<UnclaimableAirdrop_Bool_Exp>;
  _or?: InputMaybe<Array<UnclaimableAirdrop_Bool_Exp>>;
  chain?: InputMaybe<Chains_Bool_Exp>;
  chainId?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tokenAddress?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UnclaimableAirdrop" */
export enum UnclaimableAirdrop_Constraint {
  /** unique or primary key constraint on columns "id" */
  UnclaimableAirdropPkey = 'UnclaimableAirdrop_pkey'
}

/** input type for incrementing numeric columns in table "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Inc_Input = {
  chainId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Insert_Input = {
  chain?: InputMaybe<Chains_Obj_Rel_Insert_Input>;
  chainId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tokenAddress?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UnclaimableAirdrop_Max_Fields = {
  __typename?: 'UnclaimableAirdrop_max_fields';
  chainId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tokenAddress?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Max_Order_By = {
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tokenAddress?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type UnclaimableAirdrop_Min_Fields = {
  __typename?: 'UnclaimableAirdrop_min_fields';
  chainId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tokenAddress?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Min_Order_By = {
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tokenAddress?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Mutation_Response = {
  __typename?: 'UnclaimableAirdrop_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UnclaimableAirdrop>;
};

/** on_conflict condition type for table "UnclaimableAirdrop" */
export type UnclaimableAirdrop_On_Conflict = {
  constraint: UnclaimableAirdrop_Constraint;
  update_columns?: Array<UnclaimableAirdrop_Update_Column>;
  where?: InputMaybe<UnclaimableAirdrop_Bool_Exp>;
};

/** Ordering options when selecting data from "UnclaimableAirdrop". */
export type UnclaimableAirdrop_Order_By = {
  chain?: InputMaybe<Chains_Order_By>;
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tokenAddress?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UnclaimableAirdrop */
export type UnclaimableAirdrop_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "UnclaimableAirdrop" */
export enum UnclaimableAirdrop_Select_Column {
  /** column name */
  ChainId = 'chainId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TokenAddress = 'tokenAddress',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Set_Input = {
  chainId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tokenAddress?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type UnclaimableAirdrop_Stddev_Fields = {
  __typename?: 'UnclaimableAirdrop_stddev_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Stddev_Order_By = {
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type UnclaimableAirdrop_Stddev_Pop_Fields = {
  __typename?: 'UnclaimableAirdrop_stddev_pop_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Stddev_Pop_Order_By = {
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type UnclaimableAirdrop_Stddev_Samp_Fields = {
  __typename?: 'UnclaimableAirdrop_stddev_samp_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Stddev_Samp_Order_By = {
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type UnclaimableAirdrop_Sum_Fields = {
  __typename?: 'UnclaimableAirdrop_sum_fields';
  chainId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Sum_Order_By = {
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "UnclaimableAirdrop" */
export enum UnclaimableAirdrop_Update_Column {
  /** column name */
  ChainId = 'chainId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TokenAddress = 'tokenAddress',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type UnclaimableAirdrop_Var_Pop_Fields = {
  __typename?: 'UnclaimableAirdrop_var_pop_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Var_Pop_Order_By = {
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type UnclaimableAirdrop_Var_Samp_Fields = {
  __typename?: 'UnclaimableAirdrop_var_samp_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Var_Samp_Order_By = {
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type UnclaimableAirdrop_Variance_Fields = {
  __typename?: 'UnclaimableAirdrop_variance_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "UnclaimableAirdrop" */
export type UnclaimableAirdrop_Variance_Order_By = {
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type UserData = {
  __typename?: 'UserData';
  address: Scalars['String'];
  amount: Scalars['String'];
  tokenId: Scalars['String'];
};

export type UserDataInput = {
  address: Scalars['String'];
  amount: Scalars['String'];
};

export type ValidateErc20AddressInput = {
  address: Scalars['String'];
};

export type ValidateErc20AddressOutput = {
  __typename?: 'ValidateERC20AddressOutput';
  address: Scalars['String'];
  name: Scalars['String'];
  symbol: Scalars['String'];
};

export type ValidateErc721AddressInput = {
  address: Scalars['String'];
};

export type ValidateErc1155AddressInput = {
  address: Scalars['String'];
};

export type ValidateErc1155AddressOutput = {
  __typename?: 'ValidateERC1155AddressOutput';
  address: Scalars['String'];
  name: Scalars['String'];
  symbol: Scalars['String'];
};

export type VerifyContractInput = {
  airdropId: Scalars['Int'];
};

export type VerifyContractOutput = {
  __typename?: 'VerifyContractOutput';
  verified: Scalars['Boolean'];
};

/** columns and relationships of "airdrops" */
export type Airdrops = {
  __typename?: 'airdrops';
  abi: Scalars['String'];
  brandColor: Scalars['String'];
  bytecode: Scalars['String'];
  /** An object relationship */
  chain: Chains;
  chainId: Scalars['Int'];
  code: Scalars['String'];
  contractAddress: Scalars['String'];
  coverURL: Scalars['String'];
  creatorAddress: Scalars['String'];
  endDate: Scalars['timestamp'];
  id: Scalars['Int'];
  lastClaimed: Scalars['timestamp'];
  lastPolled: Scalars['timestamp'];
  listId?: Maybe<Scalars['Int']>;
  logoURL: Scalars['String'];
  merkleRoot: Scalars['String'];
  metadata?: Maybe<Scalars['jsonb']>;
  name: Scalars['String'];
  nftImageURL?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  startDate: Scalars['timestamp'];
  tokenAddress: Scalars['String'];
  tokensAvailable: Scalars['String'];
  totalClaimed: Scalars['String'];
  type: Scalars['String'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** An object relationship */
  users_list?: Maybe<Users_List>;
};


/** columns and relationships of "airdrops" */
export type AirdropsMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "airdrops" */
export type AirdropsUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "airdrops" */
export type AirdropsUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "airdrops" */
export type Airdrops_Aggregate = {
  __typename?: 'airdrops_aggregate';
  aggregate?: Maybe<Airdrops_Aggregate_Fields>;
  nodes: Array<Airdrops>;
};

/** aggregate fields of "airdrops" */
export type Airdrops_Aggregate_Fields = {
  __typename?: 'airdrops_aggregate_fields';
  avg?: Maybe<Airdrops_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Airdrops_Max_Fields>;
  min?: Maybe<Airdrops_Min_Fields>;
  stddev?: Maybe<Airdrops_Stddev_Fields>;
  stddev_pop?: Maybe<Airdrops_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Airdrops_Stddev_Samp_Fields>;
  sum?: Maybe<Airdrops_Sum_Fields>;
  var_pop?: Maybe<Airdrops_Var_Pop_Fields>;
  var_samp?: Maybe<Airdrops_Var_Samp_Fields>;
  variance?: Maybe<Airdrops_Variance_Fields>;
};


/** aggregate fields of "airdrops" */
export type Airdrops_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Airdrops_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "airdrops" */
export type Airdrops_Aggregate_Order_By = {
  avg?: InputMaybe<Airdrops_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Airdrops_Max_Order_By>;
  min?: InputMaybe<Airdrops_Min_Order_By>;
  stddev?: InputMaybe<Airdrops_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Airdrops_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Airdrops_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Airdrops_Sum_Order_By>;
  var_pop?: InputMaybe<Airdrops_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Airdrops_Var_Samp_Order_By>;
  variance?: InputMaybe<Airdrops_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Airdrops_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "airdrops" */
export type Airdrops_Arr_Rel_Insert_Input = {
  data: Array<Airdrops_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Airdrops_On_Conflict>;
};

/** aggregate avg on columns */
export type Airdrops_Avg_Fields = {
  __typename?: 'airdrops_avg_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  listId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "airdrops" */
export type Airdrops_Avg_Order_By = {
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  listId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "airdrops". All fields are combined with a logical 'AND'. */
export type Airdrops_Bool_Exp = {
  _and?: InputMaybe<Array<Airdrops_Bool_Exp>>;
  _not?: InputMaybe<Airdrops_Bool_Exp>;
  _or?: InputMaybe<Array<Airdrops_Bool_Exp>>;
  abi?: InputMaybe<String_Comparison_Exp>;
  brandColor?: InputMaybe<String_Comparison_Exp>;
  bytecode?: InputMaybe<String_Comparison_Exp>;
  chain?: InputMaybe<Chains_Bool_Exp>;
  chainId?: InputMaybe<Int_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  contractAddress?: InputMaybe<String_Comparison_Exp>;
  coverURL?: InputMaybe<String_Comparison_Exp>;
  creatorAddress?: InputMaybe<String_Comparison_Exp>;
  endDate?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lastClaimed?: InputMaybe<Timestamp_Comparison_Exp>;
  lastPolled?: InputMaybe<Timestamp_Comparison_Exp>;
  listId?: InputMaybe<Int_Comparison_Exp>;
  logoURL?: InputMaybe<String_Comparison_Exp>;
  merkleRoot?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nftImageURL?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  startDate?: InputMaybe<Timestamp_Comparison_Exp>;
  tokenAddress?: InputMaybe<String_Comparison_Exp>;
  tokensAvailable?: InputMaybe<String_Comparison_Exp>;
  totalClaimed?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
  users_list?: InputMaybe<Users_List_Bool_Exp>;
};

/** unique or primary key constraints on table "airdrops" */
export enum Airdrops_Constraint {
  /** unique or primary key constraint on columns "id" */
  AirdropsPkey = 'airdrops_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Airdrops_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Airdrops_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Airdrops_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "airdrops" */
export type Airdrops_Inc_Input = {
  chainId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  listId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "airdrops" */
export type Airdrops_Insert_Input = {
  abi?: InputMaybe<Scalars['String']>;
  brandColor?: InputMaybe<Scalars['String']>;
  bytecode?: InputMaybe<Scalars['String']>;
  chain?: InputMaybe<Chains_Obj_Rel_Insert_Input>;
  chainId?: InputMaybe<Scalars['Int']>;
  code?: InputMaybe<Scalars['String']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  coverURL?: InputMaybe<Scalars['String']>;
  creatorAddress?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  lastClaimed?: InputMaybe<Scalars['timestamp']>;
  lastPolled?: InputMaybe<Scalars['timestamp']>;
  listId?: InputMaybe<Scalars['Int']>;
  logoURL?: InputMaybe<Scalars['String']>;
  merkleRoot?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  nftImageURL?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['timestamp']>;
  tokenAddress?: InputMaybe<Scalars['String']>;
  tokensAvailable?: InputMaybe<Scalars['String']>;
  totalClaimed?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
  users_list?: InputMaybe<Users_List_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Airdrops_Max_Fields = {
  __typename?: 'airdrops_max_fields';
  abi?: Maybe<Scalars['String']>;
  brandColor?: Maybe<Scalars['String']>;
  bytecode?: Maybe<Scalars['String']>;
  chainId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  contractAddress?: Maybe<Scalars['String']>;
  coverURL?: Maybe<Scalars['String']>;
  creatorAddress?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  lastClaimed?: Maybe<Scalars['timestamp']>;
  lastPolled?: Maybe<Scalars['timestamp']>;
  listId?: Maybe<Scalars['Int']>;
  logoURL?: Maybe<Scalars['String']>;
  merkleRoot?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nftImageURL?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamp']>;
  tokenAddress?: Maybe<Scalars['String']>;
  tokensAvailable?: Maybe<Scalars['String']>;
  totalClaimed?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "airdrops" */
export type Airdrops_Max_Order_By = {
  abi?: InputMaybe<Order_By>;
  brandColor?: InputMaybe<Order_By>;
  bytecode?: InputMaybe<Order_By>;
  chainId?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  contractAddress?: InputMaybe<Order_By>;
  coverURL?: InputMaybe<Order_By>;
  creatorAddress?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastClaimed?: InputMaybe<Order_By>;
  lastPolled?: InputMaybe<Order_By>;
  listId?: InputMaybe<Order_By>;
  logoURL?: InputMaybe<Order_By>;
  merkleRoot?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nftImageURL?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  tokenAddress?: InputMaybe<Order_By>;
  tokensAvailable?: InputMaybe<Order_By>;
  totalClaimed?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Airdrops_Min_Fields = {
  __typename?: 'airdrops_min_fields';
  abi?: Maybe<Scalars['String']>;
  brandColor?: Maybe<Scalars['String']>;
  bytecode?: Maybe<Scalars['String']>;
  chainId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  contractAddress?: Maybe<Scalars['String']>;
  coverURL?: Maybe<Scalars['String']>;
  creatorAddress?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  lastClaimed?: Maybe<Scalars['timestamp']>;
  lastPolled?: Maybe<Scalars['timestamp']>;
  listId?: Maybe<Scalars['Int']>;
  logoURL?: Maybe<Scalars['String']>;
  merkleRoot?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nftImageURL?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamp']>;
  tokenAddress?: Maybe<Scalars['String']>;
  tokensAvailable?: Maybe<Scalars['String']>;
  totalClaimed?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "airdrops" */
export type Airdrops_Min_Order_By = {
  abi?: InputMaybe<Order_By>;
  brandColor?: InputMaybe<Order_By>;
  bytecode?: InputMaybe<Order_By>;
  chainId?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  contractAddress?: InputMaybe<Order_By>;
  coverURL?: InputMaybe<Order_By>;
  creatorAddress?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastClaimed?: InputMaybe<Order_By>;
  lastPolled?: InputMaybe<Order_By>;
  listId?: InputMaybe<Order_By>;
  logoURL?: InputMaybe<Order_By>;
  merkleRoot?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nftImageURL?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  tokenAddress?: InputMaybe<Order_By>;
  tokensAvailable?: InputMaybe<Order_By>;
  totalClaimed?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "airdrops" */
export type Airdrops_Mutation_Response = {
  __typename?: 'airdrops_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Airdrops>;
};

/** input type for inserting object relation for remote table "airdrops" */
export type Airdrops_Obj_Rel_Insert_Input = {
  data: Airdrops_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Airdrops_On_Conflict>;
};

/** on_conflict condition type for table "airdrops" */
export type Airdrops_On_Conflict = {
  constraint: Airdrops_Constraint;
  update_columns?: Array<Airdrops_Update_Column>;
  where?: InputMaybe<Airdrops_Bool_Exp>;
};

/** Ordering options when selecting data from "airdrops". */
export type Airdrops_Order_By = {
  abi?: InputMaybe<Order_By>;
  brandColor?: InputMaybe<Order_By>;
  bytecode?: InputMaybe<Order_By>;
  chain?: InputMaybe<Chains_Order_By>;
  chainId?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  contractAddress?: InputMaybe<Order_By>;
  coverURL?: InputMaybe<Order_By>;
  creatorAddress?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastClaimed?: InputMaybe<Order_By>;
  lastPolled?: InputMaybe<Order_By>;
  listId?: InputMaybe<Order_By>;
  logoURL?: InputMaybe<Order_By>;
  merkleRoot?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nftImageURL?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  tokenAddress?: InputMaybe<Order_By>;
  tokensAvailable?: InputMaybe<Order_By>;
  totalClaimed?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  users_list?: InputMaybe<Users_List_Order_By>;
};

/** primary key columns input for table: airdrops */
export type Airdrops_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Airdrops_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "airdrops" */
export enum Airdrops_Select_Column {
  /** column name */
  Abi = 'abi',
  /** column name */
  BrandColor = 'brandColor',
  /** column name */
  Bytecode = 'bytecode',
  /** column name */
  ChainId = 'chainId',
  /** column name */
  Code = 'code',
  /** column name */
  ContractAddress = 'contractAddress',
  /** column name */
  CoverUrl = 'coverURL',
  /** column name */
  CreatorAddress = 'creatorAddress',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  LastClaimed = 'lastClaimed',
  /** column name */
  LastPolled = 'lastPolled',
  /** column name */
  ListId = 'listId',
  /** column name */
  LogoUrl = 'logoURL',
  /** column name */
  MerkleRoot = 'merkleRoot',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  NftImageUrl = 'nftImageURL',
  /** column name */
  Slug = 'slug',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  TokenAddress = 'tokenAddress',
  /** column name */
  TokensAvailable = 'tokensAvailable',
  /** column name */
  TotalClaimed = 'totalClaimed',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "airdrops" */
export type Airdrops_Set_Input = {
  abi?: InputMaybe<Scalars['String']>;
  brandColor?: InputMaybe<Scalars['String']>;
  bytecode?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['Int']>;
  code?: InputMaybe<Scalars['String']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  coverURL?: InputMaybe<Scalars['String']>;
  creatorAddress?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  lastClaimed?: InputMaybe<Scalars['timestamp']>;
  lastPolled?: InputMaybe<Scalars['timestamp']>;
  listId?: InputMaybe<Scalars['Int']>;
  logoURL?: InputMaybe<Scalars['String']>;
  merkleRoot?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  nftImageURL?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['timestamp']>;
  tokenAddress?: InputMaybe<Scalars['String']>;
  tokensAvailable?: InputMaybe<Scalars['String']>;
  totalClaimed?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Airdrops_Stddev_Fields = {
  __typename?: 'airdrops_stddev_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  listId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "airdrops" */
export type Airdrops_Stddev_Order_By = {
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  listId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Airdrops_Stddev_Pop_Fields = {
  __typename?: 'airdrops_stddev_pop_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  listId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "airdrops" */
export type Airdrops_Stddev_Pop_Order_By = {
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  listId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Airdrops_Stddev_Samp_Fields = {
  __typename?: 'airdrops_stddev_samp_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  listId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "airdrops" */
export type Airdrops_Stddev_Samp_Order_By = {
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  listId?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Airdrops_Sum_Fields = {
  __typename?: 'airdrops_sum_fields';
  chainId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  listId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "airdrops" */
export type Airdrops_Sum_Order_By = {
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  listId?: InputMaybe<Order_By>;
};

/** update columns of table "airdrops" */
export enum Airdrops_Update_Column {
  /** column name */
  Abi = 'abi',
  /** column name */
  BrandColor = 'brandColor',
  /** column name */
  Bytecode = 'bytecode',
  /** column name */
  ChainId = 'chainId',
  /** column name */
  Code = 'code',
  /** column name */
  ContractAddress = 'contractAddress',
  /** column name */
  CoverUrl = 'coverURL',
  /** column name */
  CreatorAddress = 'creatorAddress',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  LastClaimed = 'lastClaimed',
  /** column name */
  LastPolled = 'lastPolled',
  /** column name */
  ListId = 'listId',
  /** column name */
  LogoUrl = 'logoURL',
  /** column name */
  MerkleRoot = 'merkleRoot',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  NftImageUrl = 'nftImageURL',
  /** column name */
  Slug = 'slug',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  TokenAddress = 'tokenAddress',
  /** column name */
  TokensAvailable = 'tokensAvailable',
  /** column name */
  TotalClaimed = 'totalClaimed',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Airdrops_Var_Pop_Fields = {
  __typename?: 'airdrops_var_pop_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  listId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "airdrops" */
export type Airdrops_Var_Pop_Order_By = {
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  listId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Airdrops_Var_Samp_Fields = {
  __typename?: 'airdrops_var_samp_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  listId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "airdrops" */
export type Airdrops_Var_Samp_Order_By = {
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  listId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Airdrops_Variance_Fields = {
  __typename?: 'airdrops_variance_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  listId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "airdrops" */
export type Airdrops_Variance_Order_By = {
  chainId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  listId?: InputMaybe<Order_By>;
};

/** columns and relationships of "chains" */
export type Chains = {
  __typename?: 'chains';
  /** An array relationship */
  UnclaimableAirdrops: Array<UnclaimableAirdrop>;
  /** An aggregate relationship */
  UnclaimableAirdrops_aggregate: UnclaimableAirdrop_Aggregate;
  /** An array relationship */
  airdrops: Array<Airdrops>;
  /** An aggregate relationship */
  airdrops_aggregate: Airdrops_Aggregate;
  chainId: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isTestnet: Scalars['Boolean'];
  name: Scalars['String'];
  rpcUrl?: Maybe<Scalars['String']>;
  /** An array relationship */
  tokens: Array<Tokens>;
  /** An aggregate relationship */
  tokens_aggregate: Tokens_Aggregate;
};


/** columns and relationships of "chains" */
export type ChainsUnclaimableAirdropsArgs = {
  distinct_on?: InputMaybe<Array<UnclaimableAirdrop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UnclaimableAirdrop_Order_By>>;
  where?: InputMaybe<UnclaimableAirdrop_Bool_Exp>;
};


/** columns and relationships of "chains" */
export type ChainsUnclaimableAirdrops_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UnclaimableAirdrop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UnclaimableAirdrop_Order_By>>;
  where?: InputMaybe<UnclaimableAirdrop_Bool_Exp>;
};


/** columns and relationships of "chains" */
export type ChainsAirdropsArgs = {
  distinct_on?: InputMaybe<Array<Airdrops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Airdrops_Order_By>>;
  where?: InputMaybe<Airdrops_Bool_Exp>;
};


/** columns and relationships of "chains" */
export type ChainsAirdrops_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Airdrops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Airdrops_Order_By>>;
  where?: InputMaybe<Airdrops_Bool_Exp>;
};


/** columns and relationships of "chains" */
export type ChainsTokensArgs = {
  distinct_on?: InputMaybe<Array<Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tokens_Order_By>>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};


/** columns and relationships of "chains" */
export type ChainsTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tokens_Order_By>>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};

/** aggregated selection of "chains" */
export type Chains_Aggregate = {
  __typename?: 'chains_aggregate';
  aggregate?: Maybe<Chains_Aggregate_Fields>;
  nodes: Array<Chains>;
};

/** aggregate fields of "chains" */
export type Chains_Aggregate_Fields = {
  __typename?: 'chains_aggregate_fields';
  avg?: Maybe<Chains_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Chains_Max_Fields>;
  min?: Maybe<Chains_Min_Fields>;
  stddev?: Maybe<Chains_Stddev_Fields>;
  stddev_pop?: Maybe<Chains_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Chains_Stddev_Samp_Fields>;
  sum?: Maybe<Chains_Sum_Fields>;
  var_pop?: Maybe<Chains_Var_Pop_Fields>;
  var_samp?: Maybe<Chains_Var_Samp_Fields>;
  variance?: Maybe<Chains_Variance_Fields>;
};


/** aggregate fields of "chains" */
export type Chains_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chains_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Chains_Avg_Fields = {
  __typename?: 'chains_avg_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "chains". All fields are combined with a logical 'AND'. */
export type Chains_Bool_Exp = {
  UnclaimableAirdrops?: InputMaybe<UnclaimableAirdrop_Bool_Exp>;
  _and?: InputMaybe<Array<Chains_Bool_Exp>>;
  _not?: InputMaybe<Chains_Bool_Exp>;
  _or?: InputMaybe<Array<Chains_Bool_Exp>>;
  airdrops?: InputMaybe<Airdrops_Bool_Exp>;
  chainId?: InputMaybe<Int_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  iconUrl?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isTestnet?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  rpcUrl?: InputMaybe<String_Comparison_Exp>;
  tokens?: InputMaybe<Tokens_Bool_Exp>;
};

/** unique or primary key constraints on table "chains" */
export enum Chains_Constraint {
  /** unique or primary key constraint on columns "chainId" */
  ChainsChainIdKey = 'chains_chainId_key',
  /** unique or primary key constraint on columns "id" */
  ChainsPkey = 'chains_pkey'
}

/** input type for incrementing numeric columns in table "chains" */
export type Chains_Inc_Input = {
  chainId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "chains" */
export type Chains_Insert_Input = {
  UnclaimableAirdrops?: InputMaybe<UnclaimableAirdrop_Arr_Rel_Insert_Input>;
  airdrops?: InputMaybe<Airdrops_Arr_Rel_Insert_Input>;
  chainId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isTestnet?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  rpcUrl?: InputMaybe<Scalars['String']>;
  tokens?: InputMaybe<Tokens_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Chains_Max_Fields = {
  __typename?: 'chains_max_fields';
  chainId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  rpcUrl?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Chains_Min_Fields = {
  __typename?: 'chains_min_fields';
  chainId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  rpcUrl?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "chains" */
export type Chains_Mutation_Response = {
  __typename?: 'chains_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chains>;
};

/** input type for inserting object relation for remote table "chains" */
export type Chains_Obj_Rel_Insert_Input = {
  data: Chains_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Chains_On_Conflict>;
};

/** on_conflict condition type for table "chains" */
export type Chains_On_Conflict = {
  constraint: Chains_Constraint;
  update_columns?: Array<Chains_Update_Column>;
  where?: InputMaybe<Chains_Bool_Exp>;
};

/** Ordering options when selecting data from "chains". */
export type Chains_Order_By = {
  UnclaimableAirdrops_aggregate?: InputMaybe<UnclaimableAirdrop_Aggregate_Order_By>;
  airdrops_aggregate?: InputMaybe<Airdrops_Aggregate_Order_By>;
  chainId?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  iconUrl?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isTestnet?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rpcUrl?: InputMaybe<Order_By>;
  tokens_aggregate?: InputMaybe<Tokens_Aggregate_Order_By>;
};

/** primary key columns input for table: chains */
export type Chains_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "chains" */
export enum Chains_Select_Column {
  /** column name */
  ChainId = 'chainId',
  /** column name */
  Description = 'description',
  /** column name */
  IconUrl = 'iconUrl',
  /** column name */
  Id = 'id',
  /** column name */
  IsTestnet = 'isTestnet',
  /** column name */
  Name = 'name',
  /** column name */
  RpcUrl = 'rpcUrl'
}

/** input type for updating data in table "chains" */
export type Chains_Set_Input = {
  chainId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isTestnet?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  rpcUrl?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Chains_Stddev_Fields = {
  __typename?: 'chains_stddev_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Chains_Stddev_Pop_Fields = {
  __typename?: 'chains_stddev_pop_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Chains_Stddev_Samp_Fields = {
  __typename?: 'chains_stddev_samp_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Chains_Sum_Fields = {
  __typename?: 'chains_sum_fields';
  chainId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "chains" */
export enum Chains_Update_Column {
  /** column name */
  ChainId = 'chainId',
  /** column name */
  Description = 'description',
  /** column name */
  IconUrl = 'iconUrl',
  /** column name */
  Id = 'id',
  /** column name */
  IsTestnet = 'isTestnet',
  /** column name */
  Name = 'name',
  /** column name */
  RpcUrl = 'rpcUrl'
}

/** aggregate var_pop on columns */
export type Chains_Var_Pop_Fields = {
  __typename?: 'chains_var_pop_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Chains_Var_Samp_Fields = {
  __typename?: 'chains_var_samp_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Chains_Variance_Fields = {
  __typename?: 'chains_variance_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "list_users" */
export type List_Users = {
  __typename?: 'list_users';
  address: Scalars['String'];
  amount: Scalars['String'];
  id: Scalars['Int'];
  usersListId: Scalars['Int'];
  /** An object relationship */
  users_list: Users_List;
};

/** aggregated selection of "list_users" */
export type List_Users_Aggregate = {
  __typename?: 'list_users_aggregate';
  aggregate?: Maybe<List_Users_Aggregate_Fields>;
  nodes: Array<List_Users>;
};

/** aggregate fields of "list_users" */
export type List_Users_Aggregate_Fields = {
  __typename?: 'list_users_aggregate_fields';
  avg?: Maybe<List_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<List_Users_Max_Fields>;
  min?: Maybe<List_Users_Min_Fields>;
  stddev?: Maybe<List_Users_Stddev_Fields>;
  stddev_pop?: Maybe<List_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<List_Users_Stddev_Samp_Fields>;
  sum?: Maybe<List_Users_Sum_Fields>;
  var_pop?: Maybe<List_Users_Var_Pop_Fields>;
  var_samp?: Maybe<List_Users_Var_Samp_Fields>;
  variance?: Maybe<List_Users_Variance_Fields>;
};


/** aggregate fields of "list_users" */
export type List_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<List_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "list_users" */
export type List_Users_Aggregate_Order_By = {
  avg?: InputMaybe<List_Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<List_Users_Max_Order_By>;
  min?: InputMaybe<List_Users_Min_Order_By>;
  stddev?: InputMaybe<List_Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<List_Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<List_Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<List_Users_Sum_Order_By>;
  var_pop?: InputMaybe<List_Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<List_Users_Var_Samp_Order_By>;
  variance?: InputMaybe<List_Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "list_users" */
export type List_Users_Arr_Rel_Insert_Input = {
  data: Array<List_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<List_Users_On_Conflict>;
};

/** aggregate avg on columns */
export type List_Users_Avg_Fields = {
  __typename?: 'list_users_avg_fields';
  id?: Maybe<Scalars['Float']>;
  usersListId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "list_users" */
export type List_Users_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  usersListId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "list_users". All fields are combined with a logical 'AND'. */
export type List_Users_Bool_Exp = {
  _and?: InputMaybe<Array<List_Users_Bool_Exp>>;
  _not?: InputMaybe<List_Users_Bool_Exp>;
  _or?: InputMaybe<Array<List_Users_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  amount?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  usersListId?: InputMaybe<Int_Comparison_Exp>;
  users_list?: InputMaybe<Users_List_Bool_Exp>;
};

/** unique or primary key constraints on table "list_users" */
export enum List_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  ListUsersPkey = 'list_users_pkey'
}

/** input type for incrementing numeric columns in table "list_users" */
export type List_Users_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  usersListId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "list_users" */
export type List_Users_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  usersListId?: InputMaybe<Scalars['Int']>;
  users_list?: InputMaybe<Users_List_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type List_Users_Max_Fields = {
  __typename?: 'list_users_max_fields';
  address?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  usersListId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "list_users" */
export type List_Users_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  usersListId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type List_Users_Min_Fields = {
  __typename?: 'list_users_min_fields';
  address?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  usersListId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "list_users" */
export type List_Users_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  usersListId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "list_users" */
export type List_Users_Mutation_Response = {
  __typename?: 'list_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<List_Users>;
};

/** on_conflict condition type for table "list_users" */
export type List_Users_On_Conflict = {
  constraint: List_Users_Constraint;
  update_columns?: Array<List_Users_Update_Column>;
  where?: InputMaybe<List_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "list_users". */
export type List_Users_Order_By = {
  address?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  usersListId?: InputMaybe<Order_By>;
  users_list?: InputMaybe<Users_List_Order_By>;
};

/** primary key columns input for table: list_users */
export type List_Users_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "list_users" */
export enum List_Users_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Amount = 'amount',
  /** column name */
  Id = 'id',
  /** column name */
  UsersListId = 'usersListId'
}

/** input type for updating data in table "list_users" */
export type List_Users_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  usersListId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type List_Users_Stddev_Fields = {
  __typename?: 'list_users_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  usersListId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "list_users" */
export type List_Users_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  usersListId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type List_Users_Stddev_Pop_Fields = {
  __typename?: 'list_users_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  usersListId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "list_users" */
export type List_Users_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  usersListId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type List_Users_Stddev_Samp_Fields = {
  __typename?: 'list_users_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  usersListId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "list_users" */
export type List_Users_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  usersListId?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type List_Users_Sum_Fields = {
  __typename?: 'list_users_sum_fields';
  id?: Maybe<Scalars['Int']>;
  usersListId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "list_users" */
export type List_Users_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  usersListId?: InputMaybe<Order_By>;
};

/** update columns of table "list_users" */
export enum List_Users_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Amount = 'amount',
  /** column name */
  Id = 'id',
  /** column name */
  UsersListId = 'usersListId'
}

/** aggregate var_pop on columns */
export type List_Users_Var_Pop_Fields = {
  __typename?: 'list_users_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  usersListId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "list_users" */
export type List_Users_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  usersListId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type List_Users_Var_Samp_Fields = {
  __typename?: 'list_users_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  usersListId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "list_users" */
export type List_Users_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  usersListId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type List_Users_Variance_Fields = {
  __typename?: 'list_users_variance_fields';
  id?: Maybe<Scalars['Float']>;
  usersListId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "list_users" */
export type List_Users_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  usersListId?: InputMaybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  claim?: Maybe<ClaimAirdropOutput>;
  create_airdrop: CreateAirdropOutput;
  create_erc721_airdrop: CreateErc721AirdropOutput;
  create_erc1155_airdrop: CreateErc1155AirdropOutput;
  create_native_airdrop: CreateAirdropOutput;
  create_unclaimable_airdrop: CreateUnclaimableAirdropOutput;
  /** delete data from the table: "UnclaimableAirdrop" */
  delete_UnclaimableAirdrop?: Maybe<UnclaimableAirdrop_Mutation_Response>;
  /** delete single row from the table: "UnclaimableAirdrop" */
  delete_UnclaimableAirdrop_by_pk?: Maybe<UnclaimableAirdrop>;
  /** delete data from the table: "airdrops" */
  delete_airdrops?: Maybe<Airdrops_Mutation_Response>;
  /** delete single row from the table: "airdrops" */
  delete_airdrops_by_pk?: Maybe<Airdrops>;
  /** delete data from the table: "chains" */
  delete_chains?: Maybe<Chains_Mutation_Response>;
  /** delete single row from the table: "chains" */
  delete_chains_by_pk?: Maybe<Chains>;
  /** delete data from the table: "list_users" */
  delete_list_users?: Maybe<List_Users_Mutation_Response>;
  /** delete single row from the table: "list_users" */
  delete_list_users_by_pk?: Maybe<List_Users>;
  /** delete data from the table: "sign_in_attempt" */
  delete_sign_in_attempt?: Maybe<Sign_In_Attempt_Mutation_Response>;
  /** delete single row from the table: "sign_in_attempt" */
  delete_sign_in_attempt_by_pk?: Maybe<Sign_In_Attempt>;
  /** delete data from the table: "tokens" */
  delete_tokens?: Maybe<Tokens_Mutation_Response>;
  /** delete single row from the table: "tokens" */
  delete_tokens_by_pk?: Maybe<Tokens>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "users_list" */
  delete_users_list?: Maybe<Users_List_Mutation_Response>;
  /** delete single row from the table: "users_list" */
  delete_users_list_by_pk?: Maybe<Users_List>;
  /** insert data into the table: "UnclaimableAirdrop" */
  insert_UnclaimableAirdrop?: Maybe<UnclaimableAirdrop_Mutation_Response>;
  /** insert a single row into the table: "UnclaimableAirdrop" */
  insert_UnclaimableAirdrop_one?: Maybe<UnclaimableAirdrop>;
  /** insert data into the table: "airdrops" */
  insert_airdrops?: Maybe<Airdrops_Mutation_Response>;
  /** insert a single row into the table: "airdrops" */
  insert_airdrops_one?: Maybe<Airdrops>;
  /** insert data into the table: "chains" */
  insert_chains?: Maybe<Chains_Mutation_Response>;
  /** insert a single row into the table: "chains" */
  insert_chains_one?: Maybe<Chains>;
  /** insert data into the table: "list_users" */
  insert_list_users?: Maybe<List_Users_Mutation_Response>;
  /** insert a single row into the table: "list_users" */
  insert_list_users_one?: Maybe<List_Users>;
  /** insert data into the table: "sign_in_attempt" */
  insert_sign_in_attempt?: Maybe<Sign_In_Attempt_Mutation_Response>;
  /** insert a single row into the table: "sign_in_attempt" */
  insert_sign_in_attempt_one?: Maybe<Sign_In_Attempt>;
  /** insert data into the table: "tokens" */
  insert_tokens?: Maybe<Tokens_Mutation_Response>;
  /** insert a single row into the table: "tokens" */
  insert_tokens_one?: Maybe<Tokens>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert data into the table: "users_list" */
  insert_users_list?: Maybe<Users_List_Mutation_Response>;
  /** insert a single row into the table: "users_list" */
  insert_users_list_one?: Maybe<Users_List>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  parse_csv: Array<UserData>;
  saved_list?: Maybe<SavedListOutput>;
  /** sign in */
  sign_in?: Maybe<AccessToken>;
  /** update data of the table: "UnclaimableAirdrop" */
  update_UnclaimableAirdrop?: Maybe<UnclaimableAirdrop_Mutation_Response>;
  /** update single row of the table: "UnclaimableAirdrop" */
  update_UnclaimableAirdrop_by_pk?: Maybe<UnclaimableAirdrop>;
  /** update data of the table: "airdrops" */
  update_airdrops?: Maybe<Airdrops_Mutation_Response>;
  /** update single row of the table: "airdrops" */
  update_airdrops_by_pk?: Maybe<Airdrops>;
  /** update data of the table: "chains" */
  update_chains?: Maybe<Chains_Mutation_Response>;
  /** update single row of the table: "chains" */
  update_chains_by_pk?: Maybe<Chains>;
  /** update data of the table: "list_users" */
  update_list_users?: Maybe<List_Users_Mutation_Response>;
  /** update single row of the table: "list_users" */
  update_list_users_by_pk?: Maybe<List_Users>;
  /** update data of the table: "sign_in_attempt" */
  update_sign_in_attempt?: Maybe<Sign_In_Attempt_Mutation_Response>;
  /** update single row of the table: "sign_in_attempt" */
  update_sign_in_attempt_by_pk?: Maybe<Sign_In_Attempt>;
  /** update data of the table: "tokens" */
  update_tokens?: Maybe<Tokens_Mutation_Response>;
  /** update single row of the table: "tokens" */
  update_tokens_by_pk?: Maybe<Tokens>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "users_list" */
  update_users_list?: Maybe<Users_List_Mutation_Response>;
  /** update single row of the table: "users_list" */
  update_users_list_by_pk?: Maybe<Users_List>;
  validate_erc20_address: ValidateErc20AddressOutput;
  validate_erc721_address: ValidateErc20AddressOutput;
  validate_erc1155_address: ValidateErc1155AddressOutput;
  verify_contract?: Maybe<VerifyContractOutput>;
};


/** mutation root */
export type Mutation_RootClaimArgs = {
  object: ClaimAirdropInput;
};


/** mutation root */
export type Mutation_RootCreate_AirdropArgs = {
  object: CreateAirdropInput;
};


/** mutation root */
export type Mutation_RootCreate_Erc721_AirdropArgs = {
  object: CreateErc721AirdropInput;
};


/** mutation root */
export type Mutation_RootCreate_Erc1155_AirdropArgs = {
  object: CreateErc1155AirdropInput;
};


/** mutation root */
export type Mutation_RootCreate_Native_AirdropArgs = {
  object: CreateNativeAirdropInput;
};


/** mutation root */
export type Mutation_RootCreate_Unclaimable_AirdropArgs = {
  object: CreateUnclaimableAirdropInput;
};


/** mutation root */
export type Mutation_RootDelete_UnclaimableAirdropArgs = {
  where: UnclaimableAirdrop_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UnclaimableAirdrop_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AirdropsArgs = {
  where: Airdrops_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Airdrops_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ChainsArgs = {
  where: Chains_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chains_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_List_UsersArgs = {
  where: List_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_List_Users_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Sign_In_AttemptArgs = {
  where: Sign_In_Attempt_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sign_In_Attempt_By_PkArgs = {
  message_hash: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_TokensArgs = {
  where: Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tokens_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Users_ListArgs = {
  where: Users_List_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_List_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_UnclaimableAirdropArgs = {
  objects: Array<UnclaimableAirdrop_Insert_Input>;
  on_conflict?: InputMaybe<UnclaimableAirdrop_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UnclaimableAirdrop_OneArgs = {
  object: UnclaimableAirdrop_Insert_Input;
  on_conflict?: InputMaybe<UnclaimableAirdrop_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AirdropsArgs = {
  objects: Array<Airdrops_Insert_Input>;
  on_conflict?: InputMaybe<Airdrops_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Airdrops_OneArgs = {
  object: Airdrops_Insert_Input;
  on_conflict?: InputMaybe<Airdrops_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ChainsArgs = {
  objects: Array<Chains_Insert_Input>;
  on_conflict?: InputMaybe<Chains_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chains_OneArgs = {
  object: Chains_Insert_Input;
  on_conflict?: InputMaybe<Chains_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_List_UsersArgs = {
  objects: Array<List_Users_Insert_Input>;
  on_conflict?: InputMaybe<List_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_List_Users_OneArgs = {
  object: List_Users_Insert_Input;
  on_conflict?: InputMaybe<List_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sign_In_AttemptArgs = {
  objects: Array<Sign_In_Attempt_Insert_Input>;
  on_conflict?: InputMaybe<Sign_In_Attempt_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sign_In_Attempt_OneArgs = {
  object: Sign_In_Attempt_Insert_Input;
  on_conflict?: InputMaybe<Sign_In_Attempt_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TokensArgs = {
  objects: Array<Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tokens_OneArgs = {
  object: Tokens_Insert_Input;
  on_conflict?: InputMaybe<Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_ListArgs = {
  objects: Array<Users_List_Insert_Input>;
  on_conflict?: InputMaybe<Users_List_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_List_OneArgs = {
  object: Users_List_Insert_Input;
  on_conflict?: InputMaybe<Users_List_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootParse_CsvArgs = {
  object: ParseCsvInput;
};


/** mutation root */
export type Mutation_RootSaved_ListArgs = {
  object: SavedListInput;
};


/** mutation root */
export type Mutation_RootSign_InArgs = {
  object: CreateJwtTokenInput;
};


/** mutation root */
export type Mutation_RootUpdate_UnclaimableAirdropArgs = {
  _inc?: InputMaybe<UnclaimableAirdrop_Inc_Input>;
  _set?: InputMaybe<UnclaimableAirdrop_Set_Input>;
  where: UnclaimableAirdrop_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UnclaimableAirdrop_By_PkArgs = {
  _inc?: InputMaybe<UnclaimableAirdrop_Inc_Input>;
  _set?: InputMaybe<UnclaimableAirdrop_Set_Input>;
  pk_columns: UnclaimableAirdrop_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AirdropsArgs = {
  _append?: InputMaybe<Airdrops_Append_Input>;
  _delete_at_path?: InputMaybe<Airdrops_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Airdrops_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Airdrops_Delete_Key_Input>;
  _inc?: InputMaybe<Airdrops_Inc_Input>;
  _prepend?: InputMaybe<Airdrops_Prepend_Input>;
  _set?: InputMaybe<Airdrops_Set_Input>;
  where: Airdrops_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Airdrops_By_PkArgs = {
  _append?: InputMaybe<Airdrops_Append_Input>;
  _delete_at_path?: InputMaybe<Airdrops_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Airdrops_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Airdrops_Delete_Key_Input>;
  _inc?: InputMaybe<Airdrops_Inc_Input>;
  _prepend?: InputMaybe<Airdrops_Prepend_Input>;
  _set?: InputMaybe<Airdrops_Set_Input>;
  pk_columns: Airdrops_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ChainsArgs = {
  _inc?: InputMaybe<Chains_Inc_Input>;
  _set?: InputMaybe<Chains_Set_Input>;
  where: Chains_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chains_By_PkArgs = {
  _inc?: InputMaybe<Chains_Inc_Input>;
  _set?: InputMaybe<Chains_Set_Input>;
  pk_columns: Chains_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_List_UsersArgs = {
  _inc?: InputMaybe<List_Users_Inc_Input>;
  _set?: InputMaybe<List_Users_Set_Input>;
  where: List_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_List_Users_By_PkArgs = {
  _inc?: InputMaybe<List_Users_Inc_Input>;
  _set?: InputMaybe<List_Users_Set_Input>;
  pk_columns: List_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sign_In_AttemptArgs = {
  _set?: InputMaybe<Sign_In_Attempt_Set_Input>;
  where: Sign_In_Attempt_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sign_In_Attempt_By_PkArgs = {
  _set?: InputMaybe<Sign_In_Attempt_Set_Input>;
  pk_columns: Sign_In_Attempt_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TokensArgs = {
  _inc?: InputMaybe<Tokens_Inc_Input>;
  _set?: InputMaybe<Tokens_Set_Input>;
  where: Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tokens_By_PkArgs = {
  _inc?: InputMaybe<Tokens_Inc_Input>;
  _set?: InputMaybe<Tokens_Set_Input>;
  pk_columns: Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ListArgs = {
  _inc?: InputMaybe<Users_List_Inc_Input>;
  _set?: InputMaybe<Users_List_Set_Input>;
  where: Users_List_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_List_By_PkArgs = {
  _inc?: InputMaybe<Users_List_Inc_Input>;
  _set?: InputMaybe<Users_List_Set_Input>;
  pk_columns: Users_List_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootValidate_Erc20_AddressArgs = {
  object: ValidateErc20AddressInput;
};


/** mutation root */
export type Mutation_RootValidate_Erc721_AddressArgs = {
  object: ValidateErc721AddressInput;
};


/** mutation root */
export type Mutation_RootValidate_Erc1155_AddressArgs = {
  object: ValidateErc1155AddressInput;
};


/** mutation root */
export type Mutation_RootVerify_ContractArgs = {
  object: VerifyContractInput;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "UnclaimableAirdrop" */
  UnclaimableAirdrop: Array<UnclaimableAirdrop>;
  /** fetch aggregated fields from the table: "UnclaimableAirdrop" */
  UnclaimableAirdrop_aggregate: UnclaimableAirdrop_Aggregate;
  /** fetch data from the table: "UnclaimableAirdrop" using primary key columns */
  UnclaimableAirdrop_by_pk?: Maybe<UnclaimableAirdrop>;
  /** An array relationship */
  airdrops: Array<Airdrops>;
  /** An aggregate relationship */
  airdrops_aggregate: Airdrops_Aggregate;
  /** fetch data from the table: "airdrops" using primary key columns */
  airdrops_by_pk?: Maybe<Airdrops>;
  /** fetch data from the table: "chains" */
  chains: Array<Chains>;
  /** fetch aggregated fields from the table: "chains" */
  chains_aggregate: Chains_Aggregate;
  /** fetch data from the table: "chains" using primary key columns */
  chains_by_pk?: Maybe<Chains>;
  /** An array relationship */
  list_users: Array<List_Users>;
  /** An aggregate relationship */
  list_users_aggregate: List_Users_Aggregate;
  /** fetch data from the table: "list_users" using primary key columns */
  list_users_by_pk?: Maybe<List_Users>;
  /** fetch data from the table: "sign_in_attempt" */
  sign_in_attempt: Array<Sign_In_Attempt>;
  /** fetch aggregated fields from the table: "sign_in_attempt" */
  sign_in_attempt_aggregate: Sign_In_Attempt_Aggregate;
  /** fetch data from the table: "sign_in_attempt" using primary key columns */
  sign_in_attempt_by_pk?: Maybe<Sign_In_Attempt>;
  signed_message?: Maybe<SignedMessage>;
  /** An array relationship */
  tokens: Array<Tokens>;
  /** An aggregate relationship */
  tokens_aggregate: Tokens_Aggregate;
  /** fetch data from the table: "tokens" using primary key columns */
  tokens_by_pk?: Maybe<Tokens>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_list" */
  users_list: Array<Users_List>;
  /** fetch aggregated fields from the table: "users_list" */
  users_list_aggregate: Users_List_Aggregate;
  /** fetch data from the table: "users_list" using primary key columns */
  users_list_by_pk?: Maybe<Users_List>;
};


export type Query_RootUnclaimableAirdropArgs = {
  distinct_on?: InputMaybe<Array<UnclaimableAirdrop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UnclaimableAirdrop_Order_By>>;
  where?: InputMaybe<UnclaimableAirdrop_Bool_Exp>;
};


export type Query_RootUnclaimableAirdrop_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UnclaimableAirdrop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UnclaimableAirdrop_Order_By>>;
  where?: InputMaybe<UnclaimableAirdrop_Bool_Exp>;
};


export type Query_RootUnclaimableAirdrop_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAirdropsArgs = {
  distinct_on?: InputMaybe<Array<Airdrops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Airdrops_Order_By>>;
  where?: InputMaybe<Airdrops_Bool_Exp>;
};


export type Query_RootAirdrops_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Airdrops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Airdrops_Order_By>>;
  where?: InputMaybe<Airdrops_Bool_Exp>;
};


export type Query_RootAirdrops_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootChainsArgs = {
  distinct_on?: InputMaybe<Array<Chains_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chains_Order_By>>;
  where?: InputMaybe<Chains_Bool_Exp>;
};


export type Query_RootChains_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chains_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chains_Order_By>>;
  where?: InputMaybe<Chains_Bool_Exp>;
};


export type Query_RootChains_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootList_UsersArgs = {
  distinct_on?: InputMaybe<Array<List_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Users_Order_By>>;
  where?: InputMaybe<List_Users_Bool_Exp>;
};


export type Query_RootList_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Users_Order_By>>;
  where?: InputMaybe<List_Users_Bool_Exp>;
};


export type Query_RootList_Users_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSign_In_AttemptArgs = {
  distinct_on?: InputMaybe<Array<Sign_In_Attempt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sign_In_Attempt_Order_By>>;
  where?: InputMaybe<Sign_In_Attempt_Bool_Exp>;
};


export type Query_RootSign_In_Attempt_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sign_In_Attempt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sign_In_Attempt_Order_By>>;
  where?: InputMaybe<Sign_In_Attempt_Bool_Exp>;
};


export type Query_RootSign_In_Attempt_By_PkArgs = {
  message_hash: Scalars['String'];
};


export type Query_RootTokensArgs = {
  distinct_on?: InputMaybe<Array<Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tokens_Order_By>>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};


export type Query_RootTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tokens_Order_By>>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};


export type Query_RootTokens_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUsers_ListArgs = {
  distinct_on?: InputMaybe<Array<Users_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_List_Order_By>>;
  where?: InputMaybe<Users_List_Bool_Exp>;
};


export type Query_RootUsers_List_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_List_Order_By>>;
  where?: InputMaybe<Users_List_Bool_Exp>;
};


export type Query_RootUsers_List_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "sign_in_attempt" */
export type Sign_In_Attempt = {
  __typename?: 'sign_in_attempt';
  done: Scalars['Boolean'];
  message_hash: Scalars['String'];
};

/** aggregated selection of "sign_in_attempt" */
export type Sign_In_Attempt_Aggregate = {
  __typename?: 'sign_in_attempt_aggregate';
  aggregate?: Maybe<Sign_In_Attempt_Aggregate_Fields>;
  nodes: Array<Sign_In_Attempt>;
};

/** aggregate fields of "sign_in_attempt" */
export type Sign_In_Attempt_Aggregate_Fields = {
  __typename?: 'sign_in_attempt_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Sign_In_Attempt_Max_Fields>;
  min?: Maybe<Sign_In_Attempt_Min_Fields>;
};


/** aggregate fields of "sign_in_attempt" */
export type Sign_In_Attempt_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sign_In_Attempt_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "sign_in_attempt". All fields are combined with a logical 'AND'. */
export type Sign_In_Attempt_Bool_Exp = {
  _and?: InputMaybe<Array<Sign_In_Attempt_Bool_Exp>>;
  _not?: InputMaybe<Sign_In_Attempt_Bool_Exp>;
  _or?: InputMaybe<Array<Sign_In_Attempt_Bool_Exp>>;
  done?: InputMaybe<Boolean_Comparison_Exp>;
  message_hash?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sign_in_attempt" */
export enum Sign_In_Attempt_Constraint {
  /** unique or primary key constraint on columns "message_hash" */
  SignInAttemptPkey = 'sign_in_attempt_pkey'
}

/** input type for inserting data into table "sign_in_attempt" */
export type Sign_In_Attempt_Insert_Input = {
  done?: InputMaybe<Scalars['Boolean']>;
  message_hash?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Sign_In_Attempt_Max_Fields = {
  __typename?: 'sign_in_attempt_max_fields';
  message_hash?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Sign_In_Attempt_Min_Fields = {
  __typename?: 'sign_in_attempt_min_fields';
  message_hash?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "sign_in_attempt" */
export type Sign_In_Attempt_Mutation_Response = {
  __typename?: 'sign_in_attempt_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sign_In_Attempt>;
};

/** on_conflict condition type for table "sign_in_attempt" */
export type Sign_In_Attempt_On_Conflict = {
  constraint: Sign_In_Attempt_Constraint;
  update_columns?: Array<Sign_In_Attempt_Update_Column>;
  where?: InputMaybe<Sign_In_Attempt_Bool_Exp>;
};

/** Ordering options when selecting data from "sign_in_attempt". */
export type Sign_In_Attempt_Order_By = {
  done?: InputMaybe<Order_By>;
  message_hash?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sign_in_attempt */
export type Sign_In_Attempt_Pk_Columns_Input = {
  message_hash: Scalars['String'];
};

/** select columns of table "sign_in_attempt" */
export enum Sign_In_Attempt_Select_Column {
  /** column name */
  Done = 'done',
  /** column name */
  MessageHash = 'message_hash'
}

/** input type for updating data in table "sign_in_attempt" */
export type Sign_In_Attempt_Set_Input = {
  done?: InputMaybe<Scalars['Boolean']>;
  message_hash?: InputMaybe<Scalars['String']>;
};

/** update columns of table "sign_in_attempt" */
export enum Sign_In_Attempt_Update_Column {
  /** column name */
  Done = 'done',
  /** column name */
  MessageHash = 'message_hash'
}

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "UnclaimableAirdrop" */
  UnclaimableAirdrop: Array<UnclaimableAirdrop>;
  /** fetch aggregated fields from the table: "UnclaimableAirdrop" */
  UnclaimableAirdrop_aggregate: UnclaimableAirdrop_Aggregate;
  /** fetch data from the table: "UnclaimableAirdrop" using primary key columns */
  UnclaimableAirdrop_by_pk?: Maybe<UnclaimableAirdrop>;
  /** An array relationship */
  airdrops: Array<Airdrops>;
  /** An aggregate relationship */
  airdrops_aggregate: Airdrops_Aggregate;
  /** fetch data from the table: "airdrops" using primary key columns */
  airdrops_by_pk?: Maybe<Airdrops>;
  /** fetch data from the table: "chains" */
  chains: Array<Chains>;
  /** fetch aggregated fields from the table: "chains" */
  chains_aggregate: Chains_Aggregate;
  /** fetch data from the table: "chains" using primary key columns */
  chains_by_pk?: Maybe<Chains>;
  /** An array relationship */
  list_users: Array<List_Users>;
  /** An aggregate relationship */
  list_users_aggregate: List_Users_Aggregate;
  /** fetch data from the table: "list_users" using primary key columns */
  list_users_by_pk?: Maybe<List_Users>;
  /** fetch data from the table: "sign_in_attempt" */
  sign_in_attempt: Array<Sign_In_Attempt>;
  /** fetch aggregated fields from the table: "sign_in_attempt" */
  sign_in_attempt_aggregate: Sign_In_Attempt_Aggregate;
  /** fetch data from the table: "sign_in_attempt" using primary key columns */
  sign_in_attempt_by_pk?: Maybe<Sign_In_Attempt>;
  /** An array relationship */
  tokens: Array<Tokens>;
  /** An aggregate relationship */
  tokens_aggregate: Tokens_Aggregate;
  /** fetch data from the table: "tokens" using primary key columns */
  tokens_by_pk?: Maybe<Tokens>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_list" */
  users_list: Array<Users_List>;
  /** fetch aggregated fields from the table: "users_list" */
  users_list_aggregate: Users_List_Aggregate;
  /** fetch data from the table: "users_list" using primary key columns */
  users_list_by_pk?: Maybe<Users_List>;
};


export type Subscription_RootUnclaimableAirdropArgs = {
  distinct_on?: InputMaybe<Array<UnclaimableAirdrop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UnclaimableAirdrop_Order_By>>;
  where?: InputMaybe<UnclaimableAirdrop_Bool_Exp>;
};


export type Subscription_RootUnclaimableAirdrop_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UnclaimableAirdrop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UnclaimableAirdrop_Order_By>>;
  where?: InputMaybe<UnclaimableAirdrop_Bool_Exp>;
};


export type Subscription_RootUnclaimableAirdrop_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAirdropsArgs = {
  distinct_on?: InputMaybe<Array<Airdrops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Airdrops_Order_By>>;
  where?: InputMaybe<Airdrops_Bool_Exp>;
};


export type Subscription_RootAirdrops_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Airdrops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Airdrops_Order_By>>;
  where?: InputMaybe<Airdrops_Bool_Exp>;
};


export type Subscription_RootAirdrops_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootChainsArgs = {
  distinct_on?: InputMaybe<Array<Chains_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chains_Order_By>>;
  where?: InputMaybe<Chains_Bool_Exp>;
};


export type Subscription_RootChains_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chains_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chains_Order_By>>;
  where?: InputMaybe<Chains_Bool_Exp>;
};


export type Subscription_RootChains_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootList_UsersArgs = {
  distinct_on?: InputMaybe<Array<List_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Users_Order_By>>;
  where?: InputMaybe<List_Users_Bool_Exp>;
};


export type Subscription_RootList_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Users_Order_By>>;
  where?: InputMaybe<List_Users_Bool_Exp>;
};


export type Subscription_RootList_Users_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSign_In_AttemptArgs = {
  distinct_on?: InputMaybe<Array<Sign_In_Attempt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sign_In_Attempt_Order_By>>;
  where?: InputMaybe<Sign_In_Attempt_Bool_Exp>;
};


export type Subscription_RootSign_In_Attempt_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sign_In_Attempt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sign_In_Attempt_Order_By>>;
  where?: InputMaybe<Sign_In_Attempt_Bool_Exp>;
};


export type Subscription_RootSign_In_Attempt_By_PkArgs = {
  message_hash: Scalars['String'];
};


export type Subscription_RootTokensArgs = {
  distinct_on?: InputMaybe<Array<Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tokens_Order_By>>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};


export type Subscription_RootTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tokens_Order_By>>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};


export type Subscription_RootTokens_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUsers_ListArgs = {
  distinct_on?: InputMaybe<Array<Users_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_List_Order_By>>;
  where?: InputMaybe<Users_List_Bool_Exp>;
};


export type Subscription_RootUsers_List_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_List_Order_By>>;
  where?: InputMaybe<Users_List_Bool_Exp>;
};


export type Subscription_RootUsers_List_By_PkArgs = {
  id: Scalars['Int'];
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** columns and relationships of "tokens" */
export type Tokens = {
  __typename?: 'tokens';
  address: Scalars['String'];
  /** An object relationship */
  chain: Chains;
  chainId: Scalars['Int'];
  decimals: Scalars['Int'];
  id: Scalars['Int'];
  imageURL: Scalars['String'];
  isTestnet?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  symbol: Scalars['String'];
  type: Scalars['String'];
};

/** aggregated selection of "tokens" */
export type Tokens_Aggregate = {
  __typename?: 'tokens_aggregate';
  aggregate?: Maybe<Tokens_Aggregate_Fields>;
  nodes: Array<Tokens>;
};

/** aggregate fields of "tokens" */
export type Tokens_Aggregate_Fields = {
  __typename?: 'tokens_aggregate_fields';
  avg?: Maybe<Tokens_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tokens_Max_Fields>;
  min?: Maybe<Tokens_Min_Fields>;
  stddev?: Maybe<Tokens_Stddev_Fields>;
  stddev_pop?: Maybe<Tokens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tokens_Stddev_Samp_Fields>;
  sum?: Maybe<Tokens_Sum_Fields>;
  var_pop?: Maybe<Tokens_Var_Pop_Fields>;
  var_samp?: Maybe<Tokens_Var_Samp_Fields>;
  variance?: Maybe<Tokens_Variance_Fields>;
};


/** aggregate fields of "tokens" */
export type Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tokens" */
export type Tokens_Aggregate_Order_By = {
  avg?: InputMaybe<Tokens_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tokens_Max_Order_By>;
  min?: InputMaybe<Tokens_Min_Order_By>;
  stddev?: InputMaybe<Tokens_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tokens_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tokens_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tokens_Sum_Order_By>;
  var_pop?: InputMaybe<Tokens_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tokens_Var_Samp_Order_By>;
  variance?: InputMaybe<Tokens_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tokens" */
export type Tokens_Arr_Rel_Insert_Input = {
  data: Array<Tokens_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tokens_On_Conflict>;
};

/** aggregate avg on columns */
export type Tokens_Avg_Fields = {
  __typename?: 'tokens_avg_fields';
  chainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tokens" */
export type Tokens_Avg_Order_By = {
  chainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tokens". All fields are combined with a logical 'AND'. */
export type Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Tokens_Bool_Exp>>;
  _not?: InputMaybe<Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Tokens_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  chain?: InputMaybe<Chains_Bool_Exp>;
  chainId?: InputMaybe<Int_Comparison_Exp>;
  decimals?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  imageURL?: InputMaybe<String_Comparison_Exp>;
  isTestnet?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  symbol?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tokens" */
export enum Tokens_Constraint {
  /** unique or primary key constraint on columns "id" */
  TokensPkey = 'tokens_pkey'
}

/** input type for incrementing numeric columns in table "tokens" */
export type Tokens_Inc_Input = {
  chainId?: InputMaybe<Scalars['Int']>;
  decimals?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tokens" */
export type Tokens_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  chain?: InputMaybe<Chains_Obj_Rel_Insert_Input>;
  chainId?: InputMaybe<Scalars['Int']>;
  decimals?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  imageURL?: InputMaybe<Scalars['String']>;
  isTestnet?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Tokens_Max_Fields = {
  __typename?: 'tokens_max_fields';
  address?: Maybe<Scalars['String']>;
  chainId?: Maybe<Scalars['Int']>;
  decimals?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  imageURL?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "tokens" */
export type Tokens_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  chainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageURL?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tokens_Min_Fields = {
  __typename?: 'tokens_min_fields';
  address?: Maybe<Scalars['String']>;
  chainId?: Maybe<Scalars['Int']>;
  decimals?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  imageURL?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "tokens" */
export type Tokens_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  chainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageURL?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tokens" */
export type Tokens_Mutation_Response = {
  __typename?: 'tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tokens>;
};

/** on_conflict condition type for table "tokens" */
export type Tokens_On_Conflict = {
  constraint: Tokens_Constraint;
  update_columns?: Array<Tokens_Update_Column>;
  where?: InputMaybe<Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "tokens". */
export type Tokens_Order_By = {
  address?: InputMaybe<Order_By>;
  chain?: InputMaybe<Chains_Order_By>;
  chainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageURL?: InputMaybe<Order_By>;
  isTestnet?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tokens */
export type Tokens_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "tokens" */
export enum Tokens_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  ChainId = 'chainId',
  /** column name */
  Decimals = 'decimals',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageURL',
  /** column name */
  IsTestnet = 'isTestnet',
  /** column name */
  Name = 'name',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "tokens" */
export type Tokens_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['Int']>;
  decimals?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  imageURL?: InputMaybe<Scalars['String']>;
  isTestnet?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Tokens_Stddev_Fields = {
  __typename?: 'tokens_stddev_fields';
  chainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tokens" */
export type Tokens_Stddev_Order_By = {
  chainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tokens_Stddev_Pop_Fields = {
  __typename?: 'tokens_stddev_pop_fields';
  chainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tokens" */
export type Tokens_Stddev_Pop_Order_By = {
  chainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tokens_Stddev_Samp_Fields = {
  __typename?: 'tokens_stddev_samp_fields';
  chainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tokens" */
export type Tokens_Stddev_Samp_Order_By = {
  chainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Tokens_Sum_Fields = {
  __typename?: 'tokens_sum_fields';
  chainId?: Maybe<Scalars['Int']>;
  decimals?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tokens" */
export type Tokens_Sum_Order_By = {
  chainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "tokens" */
export enum Tokens_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  ChainId = 'chainId',
  /** column name */
  Decimals = 'decimals',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'imageURL',
  /** column name */
  IsTestnet = 'isTestnet',
  /** column name */
  Name = 'name',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Tokens_Var_Pop_Fields = {
  __typename?: 'tokens_var_pop_fields';
  chainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tokens" */
export type Tokens_Var_Pop_Order_By = {
  chainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tokens_Var_Samp_Fields = {
  __typename?: 'tokens_var_samp_fields';
  chainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tokens" */
export type Tokens_Var_Samp_Order_By = {
  chainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tokens_Variance_Fields = {
  __typename?: 'tokens_variance_fields';
  chainId?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tokens" */
export type Tokens_Variance_Order_By = {
  chainId?: InputMaybe<Order_By>;
  decimals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  address: Scalars['String'];
  /** An object relationship */
  airdrop?: Maybe<Airdrops>;
  amount: Scalars['String'];
  campaignId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  avg?: InputMaybe<Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
  stddev?: InputMaybe<Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_Sum_Order_By>;
  var_pop?: InputMaybe<Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  campaignId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "users" */
export type Users_Avg_Order_By = {
  campaignId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  airdrop?: InputMaybe<Airdrops_Bool_Exp>;
  amount?: InputMaybe<String_Comparison_Exp>;
  campaignId?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  campaignId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  airdrop?: InputMaybe<Airdrops_Obj_Rel_Insert_Input>;
  amount?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "users_list" */
export type Users_List = {
  __typename?: 'users_list';
  /** An array relationship */
  airdrops: Array<Airdrops>;
  /** An aggregate relationship */
  airdrops_aggregate: Airdrops_Aggregate;
  chainId: Scalars['Int'];
  createdAt: Scalars['timestamp'];
  creatorAddress: Scalars['String'];
  id: Scalars['Int'];
  /** An array relationship */
  list_users: Array<List_Users>;
  /** An aggregate relationship */
  list_users_aggregate: List_Users_Aggregate;
  name: Scalars['String'];
};


/** columns and relationships of "users_list" */
export type Users_ListAirdropsArgs = {
  distinct_on?: InputMaybe<Array<Airdrops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Airdrops_Order_By>>;
  where?: InputMaybe<Airdrops_Bool_Exp>;
};


/** columns and relationships of "users_list" */
export type Users_ListAirdrops_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Airdrops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Airdrops_Order_By>>;
  where?: InputMaybe<Airdrops_Bool_Exp>;
};


/** columns and relationships of "users_list" */
export type Users_ListList_UsersArgs = {
  distinct_on?: InputMaybe<Array<List_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Users_Order_By>>;
  where?: InputMaybe<List_Users_Bool_Exp>;
};


/** columns and relationships of "users_list" */
export type Users_ListList_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<List_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<List_Users_Order_By>>;
  where?: InputMaybe<List_Users_Bool_Exp>;
};

/** aggregated selection of "users_list" */
export type Users_List_Aggregate = {
  __typename?: 'users_list_aggregate';
  aggregate?: Maybe<Users_List_Aggregate_Fields>;
  nodes: Array<Users_List>;
};

/** aggregate fields of "users_list" */
export type Users_List_Aggregate_Fields = {
  __typename?: 'users_list_aggregate_fields';
  avg?: Maybe<Users_List_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_List_Max_Fields>;
  min?: Maybe<Users_List_Min_Fields>;
  stddev?: Maybe<Users_List_Stddev_Fields>;
  stddev_pop?: Maybe<Users_List_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_List_Stddev_Samp_Fields>;
  sum?: Maybe<Users_List_Sum_Fields>;
  var_pop?: Maybe<Users_List_Var_Pop_Fields>;
  var_samp?: Maybe<Users_List_Var_Samp_Fields>;
  variance?: Maybe<Users_List_Variance_Fields>;
};


/** aggregate fields of "users_list" */
export type Users_List_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_List_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Users_List_Avg_Fields = {
  __typename?: 'users_list_avg_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "users_list". All fields are combined with a logical 'AND'. */
export type Users_List_Bool_Exp = {
  _and?: InputMaybe<Array<Users_List_Bool_Exp>>;
  _not?: InputMaybe<Users_List_Bool_Exp>;
  _or?: InputMaybe<Array<Users_List_Bool_Exp>>;
  airdrops?: InputMaybe<Airdrops_Bool_Exp>;
  chainId?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  creatorAddress?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  list_users?: InputMaybe<List_Users_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_list" */
export enum Users_List_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersListPkey = 'users_list_pkey'
}

/** input type for incrementing numeric columns in table "users_list" */
export type Users_List_Inc_Input = {
  chainId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "users_list" */
export type Users_List_Insert_Input = {
  airdrops?: InputMaybe<Airdrops_Arr_Rel_Insert_Input>;
  chainId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  creatorAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  list_users?: InputMaybe<List_Users_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_List_Max_Fields = {
  __typename?: 'users_list_max_fields';
  chainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  creatorAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Users_List_Min_Fields = {
  __typename?: 'users_list_min_fields';
  chainId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  creatorAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users_list" */
export type Users_List_Mutation_Response = {
  __typename?: 'users_list_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_List>;
};

/** input type for inserting object relation for remote table "users_list" */
export type Users_List_Obj_Rel_Insert_Input = {
  data: Users_List_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_List_On_Conflict>;
};

/** on_conflict condition type for table "users_list" */
export type Users_List_On_Conflict = {
  constraint: Users_List_Constraint;
  update_columns?: Array<Users_List_Update_Column>;
  where?: InputMaybe<Users_List_Bool_Exp>;
};

/** Ordering options when selecting data from "users_list". */
export type Users_List_Order_By = {
  airdrops_aggregate?: InputMaybe<Airdrops_Aggregate_Order_By>;
  chainId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  creatorAddress?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  list_users_aggregate?: InputMaybe<List_Users_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_list */
export type Users_List_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "users_list" */
export enum Users_List_Select_Column {
  /** column name */
  ChainId = 'chainId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatorAddress = 'creatorAddress',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "users_list" */
export type Users_List_Set_Input = {
  chainId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  creatorAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Users_List_Stddev_Fields = {
  __typename?: 'users_list_stddev_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Users_List_Stddev_Pop_Fields = {
  __typename?: 'users_list_stddev_pop_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Users_List_Stddev_Samp_Fields = {
  __typename?: 'users_list_stddev_samp_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Users_List_Sum_Fields = {
  __typename?: 'users_list_sum_fields';
  chainId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "users_list" */
export enum Users_List_Update_Column {
  /** column name */
  ChainId = 'chainId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatorAddress = 'creatorAddress',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Users_List_Var_Pop_Fields = {
  __typename?: 'users_list_var_pop_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Users_List_Var_Samp_Fields = {
  __typename?: 'users_list_var_samp_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Users_List_Variance_Fields = {
  __typename?: 'users_list_variance_fields';
  chainId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  address?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  campaignId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  address?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  campaignId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  address?: InputMaybe<Order_By>;
  airdrop?: InputMaybe<Airdrops_Order_By>;
  amount?: InputMaybe<Order_By>;
  campaignId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Amount = 'amount',
  /** column name */
  CampaignId = 'campaignId',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  campaignId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "users" */
export type Users_Stddev_Order_By = {
  campaignId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  campaignId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "users" */
export type Users_Stddev_Pop_Order_By = {
  campaignId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  campaignId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "users" */
export type Users_Stddev_Samp_Order_By = {
  campaignId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  campaignId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "users" */
export type Users_Sum_Order_By = {
  campaignId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Amount = 'amount',
  /** column name */
  CampaignId = 'campaignId',
  /** column name */
  Id = 'id'
}

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  campaignId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "users" */
export type Users_Var_Pop_Order_By = {
  campaignId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  campaignId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "users" */
export type Users_Var_Samp_Order_By = {
  campaignId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  campaignId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "users" */
export type Users_Variance_Order_By = {
  campaignId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type CreateAirdropMutationVariables = Exact<{
  brandColor: Scalars['String'];
  chainId: Scalars['Int'];
  coverURL: Scalars['String'];
  endDate: Scalars['String'];
  logoURL: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  tokenAddress: Scalars['String'];
  tokensAvailable: Scalars['String'];
  listId?: InputMaybe<Scalars['Int']>;
  slug: Scalars['String'];
  usersData: Array<UserDataInput> | UserDataInput;
}>;


export type CreateAirdropMutation = { __typename?: 'mutation_root', create_airdrop: { __typename?: 'CreateAirdropOutput', abi: string, airdropId: number, bytecode: string } };

export type CreateErc1155AirdropMutationVariables = Exact<{
  brandColor: Scalars['String'];
  chainId: Scalars['Int'];
  coverURL: Scalars['String'];
  endDate: Scalars['String'];
  logoURL: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  tokensAvailable: Scalars['String'];
  slug: Scalars['String'];
  usersData: Array<UserDataInput> | UserDataInput;
  tokenName: Scalars['String'];
  tokenSymbol: Scalars['String'];
  nftImageURL: Scalars['String'];
  listId?: InputMaybe<Scalars['Int']>;
}>;


export type CreateErc1155AirdropMutation = { __typename?: 'mutation_root', create_erc1155_airdrop: { __typename?: 'CreateERC1155AirdropOutput', abi: string, airdropId: number, bytecode: string } };

export type CreateErc721AirdropMutationVariables = Exact<{
  brandColor: Scalars['String'];
  chainId: Scalars['Int'];
  coverURL: Scalars['String'];
  endDate: Scalars['String'];
  logoURL: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  tokensAvailable: Scalars['String'];
  slug: Scalars['String'];
  usersData: Array<UserDataInput> | UserDataInput;
  tokenName: Scalars['String'];
  tokenSymbol: Scalars['String'];
  nftImageURL: Scalars['String'];
  listId?: InputMaybe<Scalars['Int']>;
}>;


export type CreateErc721AirdropMutation = { __typename?: 'mutation_root', create_erc721_airdrop: { __typename?: 'CreateERC721AirdropOutput', abi: string, airdropId: number, bytecode: string } };

export type CreateNativeAirdropMutationVariables = Exact<{
  brandColor: Scalars['String'];
  chainId: Scalars['Int'];
  coverURL: Scalars['String'];
  endDate: Scalars['String'];
  logoURL: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  startDate: Scalars['String'];
  usersData: Array<UserDataInput> | UserDataInput;
}>;


export type CreateNativeAirdropMutation = { __typename?: 'mutation_root', create_native_airdrop: { __typename?: 'CreateAirdropOutput', abi: string, airdropId: number, bytecode: string } };

export type CreateUnclaimableAirdropMutationVariables = Exact<{
  name: Scalars['String'];
  chainId: Scalars['Int'];
  ownerAddress: Scalars['String'];
  tokenAddress: Scalars['String'];
  tokenType: Scalars['String'];
  usersData: Array<UnclaimableAirdropUserData> | UnclaimableAirdropUserData;
}>;


export type CreateUnclaimableAirdropMutation = { __typename?: 'mutation_root', create_unclaimable_airdrop: { __typename?: 'CreateUnclaimableAirdropOutput', abi: string, calldata: string } };

export type DeleteUsersListMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteUsersListMutation = { __typename?: 'mutation_root', delete_list_users?: { __typename?: 'list_users_mutation_response', affected_rows: number } | null, delete_users_list_by_pk?: { __typename?: 'users_list', createdAt: any, id: number, name: string } | null };

export type LoginMutationVariables = Exact<{
  message: Scalars['String'];
  sig_r: Scalars['String'];
  sig_s: Scalars['String'];
  sig_v: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'mutation_root', sign_in?: { __typename?: 'AccessToken', access_token?: string | null } | null };

export type ParseCsvMutationVariables = Exact<{
  csvTextData: Scalars['String'];
  tokenType: Scalars['String'];
}>;


export type ParseCsvMutation = { __typename?: 'mutation_root', parse_csv: Array<{ __typename?: 'UserData', address: string, amount: string, tokenId: string }> };

export type SaveListMutationVariables = Exact<{
  name: Scalars['String'];
  chainId: Scalars['Int'];
  creatorAddress: Scalars['String'];
  data: Array<List_Users_Insert_Input> | List_Users_Insert_Input;
}>;


export type SaveListMutation = { __typename?: 'mutation_root', insert_users_list?: { __typename?: 'users_list_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'users_list', id: number, createdAt: any, name: string, chainId: number, creatorAddress: string }> } | null };

export type UpdateAirdropByIdMutationVariables = Exact<{
  id: Scalars['Int'];
  contractAddress?: InputMaybe<Scalars['String']>;
}>;


export type UpdateAirdropByIdMutation = { __typename?: 'mutation_root', update_airdrops_by_pk?: { __typename?: 'airdrops', brandColor: string, contractAddress: string, coverURL: string, endDate: any, id: number, logoURL: string, merkleRoot: string, name: string, startDate: any, tokenAddress: string, tokensAvailable: string, totalClaimed: string, type: string, slug: string } | null };

export type VerifyContractMutationVariables = Exact<{
  airdropId: Scalars['Int'];
}>;


export type VerifyContractMutation = { __typename?: 'mutation_root', verify_contract?: { __typename?: 'VerifyContractOutput', verified: boolean } | null };

export type GetAirdropByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetAirdropByIdQuery = { __typename?: 'query_root', airdrops_by_pk?: { __typename?: 'airdrops', abi: string, chainId: number, brandColor: string, bytecode: string, contractAddress: string, coverURL: string, endDate: any, id: number, logoURL: string, merkleRoot: string, name: string, slug: string, startDate: any, tokenAddress: string, tokensAvailable: string, totalClaimed: string, type: string, listId?: number | null, users: Array<{ __typename?: 'users', address: string, amount: string, id: number }>, users_list?: { __typename?: 'users_list', name: string } | null, users_aggregate: { __typename?: 'users_aggregate', aggregate?: { __typename?: 'users_aggregate_fields', count: number } | null } } | null };

export type GetAllAirdropsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Airdrops_Bool_Exp>;
  order_by?: InputMaybe<Array<Airdrops_Order_By> | Airdrops_Order_By>;
}>;


export type GetAllAirdropsQuery = { __typename?: 'query_root', airdrops: Array<{ __typename?: 'airdrops', abi: string, chainId: number, brandColor: string, bytecode: string, contractAddress: string, coverURL: string, endDate: any, id: number, logoURL: string, merkleRoot: string, name: string, slug: string, startDate: any, tokenAddress: string, tokensAvailable: string, totalClaimed: string, type: string, listId?: number | null, users_list?: { __typename?: 'users_list', name: string } | null, users_aggregate: { __typename?: 'users_aggregate', aggregate?: { __typename?: 'users_aggregate_fields', count: number } | null } }>, airdrops_aggregate: { __typename?: 'airdrops_aggregate', aggregate?: { __typename?: 'airdrops_aggregate_fields', count: number } | null } };

export type GetAllTokensQueryVariables = Exact<{
  where?: InputMaybe<Tokens_Bool_Exp>;
  order_by?: InputMaybe<Array<Tokens_Order_By> | Tokens_Order_By>;
}>;


export type GetAllTokensQuery = { __typename?: 'query_root', tokens: Array<{ __typename?: 'tokens', address: string, decimals: number, imageURL: string, name: string, symbol: string, chainId: number, isTestnet?: boolean | null }> };

export type GetNonceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNonceQuery = { __typename?: 'query_root', signed_message?: { __typename?: 'SignedMessage', message: string } | null };

export type GetUsersListByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetUsersListByIdQuery = { __typename?: 'query_root', users_list_by_pk?: { __typename?: 'users_list', createdAt: any, id: number, name: string, list_users_aggregate: { __typename?: 'list_users_aggregate', aggregate?: { __typename?: 'list_users_aggregate_fields', count: number } | null }, list_users: Array<{ __typename?: 'list_users', address: string, amount: string, id: number }> } | null };

export type GetUsersListsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_List_Order_By> | Users_List_Order_By>;
  where?: InputMaybe<Users_List_Bool_Exp>;
}>;


export type GetUsersListsQuery = { __typename?: 'query_root', users_list: Array<{ __typename?: 'users_list', createdAt: any, id: number, name: string, chainId: number, list_users_aggregate: { __typename?: 'list_users_aggregate', aggregate?: { __typename?: 'list_users_aggregate_fields', count: number } | null } }>, users_list_aggregate: { __typename?: 'users_list_aggregate', aggregate?: { __typename?: 'users_list_aggregate_fields', count: number } | null } };

export type ValidateAirdropSlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ValidateAirdropSlugQuery = { __typename?: 'query_root', airdrops: Array<{ __typename?: 'airdrops', id: number }> };

export type ValidateUserListNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type ValidateUserListNameQuery = { __typename?: 'query_root', users_list: Array<{ __typename?: 'users_list', id: number }> };


export const CreateAirdropDocument = gql`
    mutation CreateAirdrop($brandColor: String!, $chainId: Int!, $coverURL: String!, $endDate: String!, $logoURL: String!, $name: String!, $startDate: String!, $tokenAddress: String!, $tokensAvailable: String!, $listId: Int, $slug: String!, $usersData: [UserDataInput!]!) {
  create_airdrop(
    object: {brandColor: $brandColor, chainId: $chainId, coverURL: $coverURL, endDate: $endDate, logoURL: $logoURL, name: $name, startDate: $startDate, tokenAddress: $tokenAddress, listId: $listId, tokensAvailable: $tokensAvailable, slug: $slug, usersData: $usersData}
  ) {
    abi
    airdropId
    bytecode
  }
}
    `;
export type CreateAirdropMutationFn = Apollo.MutationFunction<CreateAirdropMutation, CreateAirdropMutationVariables>;

/**
 * __useCreateAirdropMutation__
 *
 * To run a mutation, you first call `useCreateAirdropMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAirdropMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAirdropMutation, { data, loading, error }] = useCreateAirdropMutation({
 *   variables: {
 *      brandColor: // value for 'brandColor'
 *      chainId: // value for 'chainId'
 *      coverURL: // value for 'coverURL'
 *      endDate: // value for 'endDate'
 *      logoURL: // value for 'logoURL'
 *      name: // value for 'name'
 *      startDate: // value for 'startDate'
 *      tokenAddress: // value for 'tokenAddress'
 *      tokensAvailable: // value for 'tokensAvailable'
 *      listId: // value for 'listId'
 *      slug: // value for 'slug'
 *      usersData: // value for 'usersData'
 *   },
 * });
 */
export function useCreateAirdropMutation(baseOptions?: Apollo.MutationHookOptions<CreateAirdropMutation, CreateAirdropMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAirdropMutation, CreateAirdropMutationVariables>(CreateAirdropDocument, options);
      }
export type CreateAirdropMutationHookResult = ReturnType<typeof useCreateAirdropMutation>;
export type CreateAirdropMutationResult = Apollo.MutationResult<CreateAirdropMutation>;
export type CreateAirdropMutationOptions = Apollo.BaseMutationOptions<CreateAirdropMutation, CreateAirdropMutationVariables>;
export const CreateErc1155AirdropDocument = gql`
    mutation CreateErc1155Airdrop($brandColor: String!, $chainId: Int!, $coverURL: String!, $endDate: String!, $logoURL: String!, $name: String!, $startDate: String!, $tokensAvailable: String!, $slug: String!, $usersData: [UserDataInput!]!, $tokenName: String!, $tokenSymbol: String!, $nftImageURL: String!, $listId: Int) {
  create_erc1155_airdrop(
    object: {brandColor: $brandColor, chainId: $chainId, coverURL: $coverURL, endDate: $endDate, logoURL: $logoURL, name: $name, startDate: $startDate, tokensAvailable: $tokensAvailable, slug: $slug, usersData: $usersData, tokenName: $tokenName, tokenSymbol: $tokenSymbol, nftImageURL: $nftImageURL, listId: $listId}
  ) {
    abi
    airdropId
    bytecode
  }
}
    `;
export type CreateErc1155AirdropMutationFn = Apollo.MutationFunction<CreateErc1155AirdropMutation, CreateErc1155AirdropMutationVariables>;

/**
 * __useCreateErc1155AirdropMutation__
 *
 * To run a mutation, you first call `useCreateErc1155AirdropMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateErc1155AirdropMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createErc1155AirdropMutation, { data, loading, error }] = useCreateErc1155AirdropMutation({
 *   variables: {
 *      brandColor: // value for 'brandColor'
 *      chainId: // value for 'chainId'
 *      coverURL: // value for 'coverURL'
 *      endDate: // value for 'endDate'
 *      logoURL: // value for 'logoURL'
 *      name: // value for 'name'
 *      startDate: // value for 'startDate'
 *      tokensAvailable: // value for 'tokensAvailable'
 *      slug: // value for 'slug'
 *      usersData: // value for 'usersData'
 *      tokenName: // value for 'tokenName'
 *      tokenSymbol: // value for 'tokenSymbol'
 *      nftImageURL: // value for 'nftImageURL'
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useCreateErc1155AirdropMutation(baseOptions?: Apollo.MutationHookOptions<CreateErc1155AirdropMutation, CreateErc1155AirdropMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateErc1155AirdropMutation, CreateErc1155AirdropMutationVariables>(CreateErc1155AirdropDocument, options);
      }
export type CreateErc1155AirdropMutationHookResult = ReturnType<typeof useCreateErc1155AirdropMutation>;
export type CreateErc1155AirdropMutationResult = Apollo.MutationResult<CreateErc1155AirdropMutation>;
export type CreateErc1155AirdropMutationOptions = Apollo.BaseMutationOptions<CreateErc1155AirdropMutation, CreateErc1155AirdropMutationVariables>;
export const CreateErc721AirdropDocument = gql`
    mutation CreateErc721Airdrop($brandColor: String!, $chainId: Int!, $coverURL: String!, $endDate: String!, $logoURL: String!, $name: String!, $startDate: String!, $tokensAvailable: String!, $slug: String!, $usersData: [UserDataInput!]!, $tokenName: String!, $tokenSymbol: String!, $nftImageURL: String!, $listId: Int) {
  create_erc721_airdrop(
    object: {brandColor: $brandColor, chainId: $chainId, coverURL: $coverURL, endDate: $endDate, logoURL: $logoURL, name: $name, startDate: $startDate, tokensAvailable: $tokensAvailable, slug: $slug, usersData: $usersData, tokenName: $tokenName, tokenSymbol: $tokenSymbol, nftImageURL: $nftImageURL, listId: $listId}
  ) {
    abi
    airdropId
    bytecode
  }
}
    `;
export type CreateErc721AirdropMutationFn = Apollo.MutationFunction<CreateErc721AirdropMutation, CreateErc721AirdropMutationVariables>;

/**
 * __useCreateErc721AirdropMutation__
 *
 * To run a mutation, you first call `useCreateErc721AirdropMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateErc721AirdropMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createErc721AirdropMutation, { data, loading, error }] = useCreateErc721AirdropMutation({
 *   variables: {
 *      brandColor: // value for 'brandColor'
 *      chainId: // value for 'chainId'
 *      coverURL: // value for 'coverURL'
 *      endDate: // value for 'endDate'
 *      logoURL: // value for 'logoURL'
 *      name: // value for 'name'
 *      startDate: // value for 'startDate'
 *      tokensAvailable: // value for 'tokensAvailable'
 *      slug: // value for 'slug'
 *      usersData: // value for 'usersData'
 *      tokenName: // value for 'tokenName'
 *      tokenSymbol: // value for 'tokenSymbol'
 *      nftImageURL: // value for 'nftImageURL'
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useCreateErc721AirdropMutation(baseOptions?: Apollo.MutationHookOptions<CreateErc721AirdropMutation, CreateErc721AirdropMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateErc721AirdropMutation, CreateErc721AirdropMutationVariables>(CreateErc721AirdropDocument, options);
      }
export type CreateErc721AirdropMutationHookResult = ReturnType<typeof useCreateErc721AirdropMutation>;
export type CreateErc721AirdropMutationResult = Apollo.MutationResult<CreateErc721AirdropMutation>;
export type CreateErc721AirdropMutationOptions = Apollo.BaseMutationOptions<CreateErc721AirdropMutation, CreateErc721AirdropMutationVariables>;
export const CreateNativeAirdropDocument = gql`
    mutation CreateNativeAirdrop($brandColor: String!, $chainId: Int!, $coverURL: String!, $endDate: String!, $logoURL: String!, $name: String!, $slug: String!, $startDate: String!, $usersData: [UserDataInput!]!) {
  create_native_airdrop(
    object: {brandColor: $brandColor, chainId: $chainId, coverURL: $coverURL, endDate: $endDate, logoURL: $logoURL, name: $name, slug: $slug, startDate: $startDate, usersData: $usersData}
  ) {
    abi
    airdropId
    bytecode
  }
}
    `;
export type CreateNativeAirdropMutationFn = Apollo.MutationFunction<CreateNativeAirdropMutation, CreateNativeAirdropMutationVariables>;

/**
 * __useCreateNativeAirdropMutation__
 *
 * To run a mutation, you first call `useCreateNativeAirdropMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNativeAirdropMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNativeAirdropMutation, { data, loading, error }] = useCreateNativeAirdropMutation({
 *   variables: {
 *      brandColor: // value for 'brandColor'
 *      chainId: // value for 'chainId'
 *      coverURL: // value for 'coverURL'
 *      endDate: // value for 'endDate'
 *      logoURL: // value for 'logoURL'
 *      name: // value for 'name'
 *      slug: // value for 'slug'
 *      startDate: // value for 'startDate'
 *      usersData: // value for 'usersData'
 *   },
 * });
 */
export function useCreateNativeAirdropMutation(baseOptions?: Apollo.MutationHookOptions<CreateNativeAirdropMutation, CreateNativeAirdropMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNativeAirdropMutation, CreateNativeAirdropMutationVariables>(CreateNativeAirdropDocument, options);
      }
export type CreateNativeAirdropMutationHookResult = ReturnType<typeof useCreateNativeAirdropMutation>;
export type CreateNativeAirdropMutationResult = Apollo.MutationResult<CreateNativeAirdropMutation>;
export type CreateNativeAirdropMutationOptions = Apollo.BaseMutationOptions<CreateNativeAirdropMutation, CreateNativeAirdropMutationVariables>;
export const CreateUnclaimableAirdropDocument = gql`
    mutation CreateUnclaimableAirdrop($name: String!, $chainId: Int!, $ownerAddress: String!, $tokenAddress: String!, $tokenType: String!, $usersData: [UnclaimableAirdropUserData!]!) {
  create_unclaimable_airdrop(
    object: {name: $name, chainId: $chainId, ownerAddress: $ownerAddress, tokenAddress: $tokenAddress, tokenType: $tokenType, usersData: $usersData}
  ) {
    abi
    calldata
  }
}
    `;
export type CreateUnclaimableAirdropMutationFn = Apollo.MutationFunction<CreateUnclaimableAirdropMutation, CreateUnclaimableAirdropMutationVariables>;

/**
 * __useCreateUnclaimableAirdropMutation__
 *
 * To run a mutation, you first call `useCreateUnclaimableAirdropMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnclaimableAirdropMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnclaimableAirdropMutation, { data, loading, error }] = useCreateUnclaimableAirdropMutation({
 *   variables: {
 *      name: // value for 'name'
 *      chainId: // value for 'chainId'
 *      ownerAddress: // value for 'ownerAddress'
 *      tokenAddress: // value for 'tokenAddress'
 *      tokenType: // value for 'tokenType'
 *      usersData: // value for 'usersData'
 *   },
 * });
 */
export function useCreateUnclaimableAirdropMutation(baseOptions?: Apollo.MutationHookOptions<CreateUnclaimableAirdropMutation, CreateUnclaimableAirdropMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUnclaimableAirdropMutation, CreateUnclaimableAirdropMutationVariables>(CreateUnclaimableAirdropDocument, options);
      }
export type CreateUnclaimableAirdropMutationHookResult = ReturnType<typeof useCreateUnclaimableAirdropMutation>;
export type CreateUnclaimableAirdropMutationResult = Apollo.MutationResult<CreateUnclaimableAirdropMutation>;
export type CreateUnclaimableAirdropMutationOptions = Apollo.BaseMutationOptions<CreateUnclaimableAirdropMutation, CreateUnclaimableAirdropMutationVariables>;
export const DeleteUsersListDocument = gql`
    mutation DeleteUsersList($id: Int!) {
  delete_list_users(where: {usersListId: {_eq: $id}}) {
    affected_rows
  }
  delete_users_list_by_pk(id: $id) {
    createdAt
    id
    name
  }
}
    `;
export type DeleteUsersListMutationFn = Apollo.MutationFunction<DeleteUsersListMutation, DeleteUsersListMutationVariables>;

/**
 * __useDeleteUsersListMutation__
 *
 * To run a mutation, you first call `useDeleteUsersListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersListMutation, { data, loading, error }] = useDeleteUsersListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUsersListMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUsersListMutation, DeleteUsersListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUsersListMutation, DeleteUsersListMutationVariables>(DeleteUsersListDocument, options);
      }
export type DeleteUsersListMutationHookResult = ReturnType<typeof useDeleteUsersListMutation>;
export type DeleteUsersListMutationResult = Apollo.MutationResult<DeleteUsersListMutation>;
export type DeleteUsersListMutationOptions = Apollo.BaseMutationOptions<DeleteUsersListMutation, DeleteUsersListMutationVariables>;
export const LoginDocument = gql`
    mutation Login($message: String!, $sig_r: String!, $sig_s: String!, $sig_v: String!) {
  sign_in(
    object: {message: $message, sig_r: $sig_r, sig_s: $sig_s, sig_v: $sig_v}
  ) {
    access_token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      message: // value for 'message'
 *      sig_r: // value for 'sig_r'
 *      sig_s: // value for 'sig_s'
 *      sig_v: // value for 'sig_v'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ParseCsvDocument = gql`
    mutation parseCsv($csvTextData: String!, $tokenType: String!) {
  parse_csv(object: {csvData: $csvTextData, tokenType: $tokenType}) {
    address
    amount
    tokenId
  }
}
    `;
export type ParseCsvMutationFn = Apollo.MutationFunction<ParseCsvMutation, ParseCsvMutationVariables>;

/**
 * __useParseCsvMutation__
 *
 * To run a mutation, you first call `useParseCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParseCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parseCsvMutation, { data, loading, error }] = useParseCsvMutation({
 *   variables: {
 *      csvTextData: // value for 'csvTextData'
 *      tokenType: // value for 'tokenType'
 *   },
 * });
 */
export function useParseCsvMutation(baseOptions?: Apollo.MutationHookOptions<ParseCsvMutation, ParseCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ParseCsvMutation, ParseCsvMutationVariables>(ParseCsvDocument, options);
      }
export type ParseCsvMutationHookResult = ReturnType<typeof useParseCsvMutation>;
export type ParseCsvMutationResult = Apollo.MutationResult<ParseCsvMutation>;
export type ParseCsvMutationOptions = Apollo.BaseMutationOptions<ParseCsvMutation, ParseCsvMutationVariables>;
export const SaveListDocument = gql`
    mutation SaveList($name: String!, $chainId: Int!, $creatorAddress: String!, $data: [list_users_insert_input!]!) {
  insert_users_list(
    objects: {name: $name, list_users: {data: $data}, chainId: $chainId, creatorAddress: $creatorAddress}
  ) {
    returning {
      id
      createdAt
      name
      chainId
      creatorAddress
    }
    affected_rows
  }
}
    `;
export type SaveListMutationFn = Apollo.MutationFunction<SaveListMutation, SaveListMutationVariables>;

/**
 * __useSaveListMutation__
 *
 * To run a mutation, you first call `useSaveListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveListMutation, { data, loading, error }] = useSaveListMutation({
 *   variables: {
 *      name: // value for 'name'
 *      chainId: // value for 'chainId'
 *      creatorAddress: // value for 'creatorAddress'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveListMutation(baseOptions?: Apollo.MutationHookOptions<SaveListMutation, SaveListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveListMutation, SaveListMutationVariables>(SaveListDocument, options);
      }
export type SaveListMutationHookResult = ReturnType<typeof useSaveListMutation>;
export type SaveListMutationResult = Apollo.MutationResult<SaveListMutation>;
export type SaveListMutationOptions = Apollo.BaseMutationOptions<SaveListMutation, SaveListMutationVariables>;
export const UpdateAirdropByIdDocument = gql`
    mutation UpdateAirdropById($id: Int!, $contractAddress: String = "") {
  update_airdrops_by_pk(
    pk_columns: {id: $id}
    _set: {contractAddress: $contractAddress}
  ) {
    brandColor
    contractAddress
    coverURL
    endDate
    id
    logoURL
    merkleRoot
    name
    startDate
    tokenAddress
    tokensAvailable
    totalClaimed
    type
    slug
  }
}
    `;
export type UpdateAirdropByIdMutationFn = Apollo.MutationFunction<UpdateAirdropByIdMutation, UpdateAirdropByIdMutationVariables>;

/**
 * __useUpdateAirdropByIdMutation__
 *
 * To run a mutation, you first call `useUpdateAirdropByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAirdropByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAirdropByIdMutation, { data, loading, error }] = useUpdateAirdropByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contractAddress: // value for 'contractAddress'
 *   },
 * });
 */
export function useUpdateAirdropByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAirdropByIdMutation, UpdateAirdropByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAirdropByIdMutation, UpdateAirdropByIdMutationVariables>(UpdateAirdropByIdDocument, options);
      }
export type UpdateAirdropByIdMutationHookResult = ReturnType<typeof useUpdateAirdropByIdMutation>;
export type UpdateAirdropByIdMutationResult = Apollo.MutationResult<UpdateAirdropByIdMutation>;
export type UpdateAirdropByIdMutationOptions = Apollo.BaseMutationOptions<UpdateAirdropByIdMutation, UpdateAirdropByIdMutationVariables>;
export const VerifyContractDocument = gql`
    mutation VerifyContract($airdropId: Int!) {
  verify_contract(object: {airdropId: $airdropId}) {
    verified
  }
}
    `;
export type VerifyContractMutationFn = Apollo.MutationFunction<VerifyContractMutation, VerifyContractMutationVariables>;

/**
 * __useVerifyContractMutation__
 *
 * To run a mutation, you first call `useVerifyContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyContractMutation, { data, loading, error }] = useVerifyContractMutation({
 *   variables: {
 *      airdropId: // value for 'airdropId'
 *   },
 * });
 */
export function useVerifyContractMutation(baseOptions?: Apollo.MutationHookOptions<VerifyContractMutation, VerifyContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyContractMutation, VerifyContractMutationVariables>(VerifyContractDocument, options);
      }
export type VerifyContractMutationHookResult = ReturnType<typeof useVerifyContractMutation>;
export type VerifyContractMutationResult = Apollo.MutationResult<VerifyContractMutation>;
export type VerifyContractMutationOptions = Apollo.BaseMutationOptions<VerifyContractMutation, VerifyContractMutationVariables>;
export const GetAirdropByIdDocument = gql`
    query GetAirdropById($id: Int!) {
  airdrops_by_pk(id: $id) {
    abi
    chainId
    brandColor
    bytecode
    contractAddress
    coverURL
    endDate
    id
    logoURL
    merkleRoot
    name
    slug
    startDate
    tokenAddress
    tokensAvailable
    totalClaimed
    type
    listId
    users {
      address
      amount
      id
    }
    users_list {
      name
    }
    users_aggregate(distinct_on: address) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetAirdropByIdQuery__
 *
 * To run a query within a React component, call `useGetAirdropByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAirdropByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAirdropByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAirdropByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAirdropByIdQuery, GetAirdropByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAirdropByIdQuery, GetAirdropByIdQueryVariables>(GetAirdropByIdDocument, options);
      }
export function useGetAirdropByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAirdropByIdQuery, GetAirdropByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAirdropByIdQuery, GetAirdropByIdQueryVariables>(GetAirdropByIdDocument, options);
        }
export type GetAirdropByIdQueryHookResult = ReturnType<typeof useGetAirdropByIdQuery>;
export type GetAirdropByIdLazyQueryHookResult = ReturnType<typeof useGetAirdropByIdLazyQuery>;
export type GetAirdropByIdQueryResult = Apollo.QueryResult<GetAirdropByIdQuery, GetAirdropByIdQueryVariables>;
export const GetAllAirdropsDocument = gql`
    query GetAllAirdrops($limit: Int = 10, $offset: Int = 10, $where: airdrops_bool_exp = {}, $order_by: [airdrops_order_by!] = {}) {
  airdrops(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
    abi
    chainId
    brandColor
    bytecode
    contractAddress
    coverURL
    endDate
    id
    logoURL
    merkleRoot
    name
    slug
    startDate
    tokenAddress
    tokensAvailable
    totalClaimed
    type
    listId
    users_list {
      name
    }
    users_aggregate(distinct_on: address) {
      aggregate {
        count
      }
    }
  }
  airdrops_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAllAirdropsQuery__
 *
 * To run a query within a React component, call `useGetAllAirdropsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAirdropsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAirdropsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetAllAirdropsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAirdropsQuery, GetAllAirdropsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAirdropsQuery, GetAllAirdropsQueryVariables>(GetAllAirdropsDocument, options);
      }
export function useGetAllAirdropsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAirdropsQuery, GetAllAirdropsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAirdropsQuery, GetAllAirdropsQueryVariables>(GetAllAirdropsDocument, options);
        }
export type GetAllAirdropsQueryHookResult = ReturnType<typeof useGetAllAirdropsQuery>;
export type GetAllAirdropsLazyQueryHookResult = ReturnType<typeof useGetAllAirdropsLazyQuery>;
export type GetAllAirdropsQueryResult = Apollo.QueryResult<GetAllAirdropsQuery, GetAllAirdropsQueryVariables>;
export const GetAllTokensDocument = gql`
    query GetAllTokens($where: tokens_bool_exp = {}, $order_by: [tokens_order_by!] = {}) {
  tokens(where: $where, order_by: $order_by) {
    address
    decimals
    imageURL
    name
    symbol
    chainId
    isTestnet
  }
}
    `;

/**
 * __useGetAllTokensQuery__
 *
 * To run a query within a React component, call `useGetAllTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTokensQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetAllTokensQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTokensQuery, GetAllTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTokensQuery, GetAllTokensQueryVariables>(GetAllTokensDocument, options);
      }
export function useGetAllTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTokensQuery, GetAllTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTokensQuery, GetAllTokensQueryVariables>(GetAllTokensDocument, options);
        }
export type GetAllTokensQueryHookResult = ReturnType<typeof useGetAllTokensQuery>;
export type GetAllTokensLazyQueryHookResult = ReturnType<typeof useGetAllTokensLazyQuery>;
export type GetAllTokensQueryResult = Apollo.QueryResult<GetAllTokensQuery, GetAllTokensQueryVariables>;
export const GetNonceDocument = gql`
    query GetNonce {
  signed_message {
    message
  }
}
    `;

/**
 * __useGetNonceQuery__
 *
 * To run a query within a React component, call `useGetNonceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNonceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNonceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNonceQuery(baseOptions?: Apollo.QueryHookOptions<GetNonceQuery, GetNonceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNonceQuery, GetNonceQueryVariables>(GetNonceDocument, options);
      }
export function useGetNonceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNonceQuery, GetNonceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNonceQuery, GetNonceQueryVariables>(GetNonceDocument, options);
        }
export type GetNonceQueryHookResult = ReturnType<typeof useGetNonceQuery>;
export type GetNonceLazyQueryHookResult = ReturnType<typeof useGetNonceLazyQuery>;
export type GetNonceQueryResult = Apollo.QueryResult<GetNonceQuery, GetNonceQueryVariables>;
export const GetUsersListByIdDocument = gql`
    query GetUsersListById($id: Int!) {
  users_list_by_pk(id: $id) {
    createdAt
    id
    name
    list_users_aggregate {
      aggregate {
        count(columns: id)
      }
    }
    list_users {
      address
      amount
      id
    }
  }
}
    `;

/**
 * __useGetUsersListByIdQuery__
 *
 * To run a query within a React component, call `useGetUsersListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUsersListByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUsersListByIdQuery, GetUsersListByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersListByIdQuery, GetUsersListByIdQueryVariables>(GetUsersListByIdDocument, options);
      }
export function useGetUsersListByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersListByIdQuery, GetUsersListByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersListByIdQuery, GetUsersListByIdQueryVariables>(GetUsersListByIdDocument, options);
        }
export type GetUsersListByIdQueryHookResult = ReturnType<typeof useGetUsersListByIdQuery>;
export type GetUsersListByIdLazyQueryHookResult = ReturnType<typeof useGetUsersListByIdLazyQuery>;
export type GetUsersListByIdQueryResult = Apollo.QueryResult<GetUsersListByIdQuery, GetUsersListByIdQueryVariables>;
export const GetUsersListsDocument = gql`
    query GetUsersLists($limit: Int = 10, $offset: Int = 0, $order_by: [users_list_order_by!] = {}, $where: users_list_bool_exp = {}) {
  users_list(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
    createdAt
    id
    name
    chainId
    list_users_aggregate {
      aggregate {
        count(columns: id)
      }
    }
  }
  users_list_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetUsersListsQuery__
 *
 * To run a query within a React component, call `useGetUsersListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersListsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUsersListsQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersListsQuery, GetUsersListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersListsQuery, GetUsersListsQueryVariables>(GetUsersListsDocument, options);
      }
export function useGetUsersListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersListsQuery, GetUsersListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersListsQuery, GetUsersListsQueryVariables>(GetUsersListsDocument, options);
        }
export type GetUsersListsQueryHookResult = ReturnType<typeof useGetUsersListsQuery>;
export type GetUsersListsLazyQueryHookResult = ReturnType<typeof useGetUsersListsLazyQuery>;
export type GetUsersListsQueryResult = Apollo.QueryResult<GetUsersListsQuery, GetUsersListsQueryVariables>;
export const ValidateAirdropSlugDocument = gql`
    query ValidateAirdropSlug($slug: String!) {
  airdrops(where: {slug: {_eq: $slug}}) {
    id
  }
}
    `;

/**
 * __useValidateAirdropSlugQuery__
 *
 * To run a query within a React component, call `useValidateAirdropSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateAirdropSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateAirdropSlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useValidateAirdropSlugQuery(baseOptions: Apollo.QueryHookOptions<ValidateAirdropSlugQuery, ValidateAirdropSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateAirdropSlugQuery, ValidateAirdropSlugQueryVariables>(ValidateAirdropSlugDocument, options);
      }
export function useValidateAirdropSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateAirdropSlugQuery, ValidateAirdropSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateAirdropSlugQuery, ValidateAirdropSlugQueryVariables>(ValidateAirdropSlugDocument, options);
        }
export type ValidateAirdropSlugQueryHookResult = ReturnType<typeof useValidateAirdropSlugQuery>;
export type ValidateAirdropSlugLazyQueryHookResult = ReturnType<typeof useValidateAirdropSlugLazyQuery>;
export type ValidateAirdropSlugQueryResult = Apollo.QueryResult<ValidateAirdropSlugQuery, ValidateAirdropSlugQueryVariables>;
export const ValidateUserListNameDocument = gql`
    query ValidateUserListName($name: String!) {
  users_list(where: {name: {_eq: $name}}) {
    id
  }
}
    `;

/**
 * __useValidateUserListNameQuery__
 *
 * To run a query within a React component, call `useValidateUserListNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateUserListNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateUserListNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useValidateUserListNameQuery(baseOptions: Apollo.QueryHookOptions<ValidateUserListNameQuery, ValidateUserListNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateUserListNameQuery, ValidateUserListNameQueryVariables>(ValidateUserListNameDocument, options);
      }
export function useValidateUserListNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateUserListNameQuery, ValidateUserListNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateUserListNameQuery, ValidateUserListNameQueryVariables>(ValidateUserListNameDocument, options);
        }
export type ValidateUserListNameQueryHookResult = ReturnType<typeof useValidateUserListNameQuery>;
export type ValidateUserListNameLazyQueryHookResult = ReturnType<typeof useValidateUserListNameLazyQuery>;
export type ValidateUserListNameQueryResult = Apollo.QueryResult<ValidateUserListNameQuery, ValidateUserListNameQueryVariables>;