import { useEffect, useState, memo } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { LoadingOverlay } from '@mantine/core';
import { useRouter } from 'next/router';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';
import { showNotification } from '@mantine/notifications';

import { useGetNonceLazyQuery, useLoginMutation } from '../generated/graphql';

import { Mixpanel } from '../utils/mixpanel';
import withApollo from '../lib/withApollo';
import { ethers } from 'ethers';

const AuthGateWrapper = styled.main`
  width: 100%;
  height: 100vh;

  position: fixed;
  inset: 0;
  z-index: 10000;
`;

interface AuthGateProps {
  className?: string;
}

const AuthGate = ({ className }: AuthGateProps): JSX.Element => {
  const { replace, pathname } = useRouter();

  const [isSigningMessage, setIsSigningMessage] = useState(false);

  const [getNonceQuery, { data: nonce }] = useGetNonceLazyQuery({
    errorPolicy: 'all',
    onError(error) {
      showNotification({ title: error.name, message: error.message });
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const [loginMutation] = useLoginMutation({ errorPolicy: 'all' });

  const { address, isConnected, isDisconnected, isConnecting, isReconnecting } =
    useAccount();
  const { disconnect } = useDisconnect();
  const { signMessageAsync } = useSignMessage({
    onError(error) {
      showNotification({
        title: error.name,
        message: error.message.split('(')[0],
        color: 'red',
      });
    },
  });

  useEffect(() => {
    getNonceQuery();
  }, [getNonceQuery]);

  useEffect(() => {
    const accessToken = localStorage.getItem(`token`);

    if ((isConnecting || isReconnecting) && !accessToken && getNonceQuery) {
      getNonceQuery();
    }
  }, [isConnecting, isReconnecting, getNonceQuery]);

  useEffect(() => {
    try {
      if (isConnected) return;

      if (!address && isDisconnected) {
        if (pathname !== '/') {
          replace('/');
        }
        localStorage.removeItem('token');
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }, [replace, pathname, disconnect, address, isDisconnected, isConnected]);

  useEffect(() => {
    async function authenticateWithSignature() {
      try {
        if (isConnected && !nonce?.signed_message?.message) {
          disconnect();
          return showNotification({
            title: 'Nonce Error!',
            message: 'Nonce not found',
          });
        }

        const accessToken = localStorage.getItem(`token`);

        if (
          isConnected &&
          !isSigningMessage &&
          nonce?.signed_message?.message &&
          !accessToken
        ) {
          let signature: `0x${string}`;
          setIsSigningMessage(true);
          signature = await signMessageAsync({
            message: nonce?.signed_message?.message!,
          });

          const splitSignature = ethers.utils.splitSignature(signature);

          const { data, errors } = await loginMutation({
            variables: {
              message: nonce.signed_message.message,
              sig_r: splitSignature.r,
              sig_s: splitSignature.s,
              sig_v: '0x' + splitSignature.v.toString(16),
            },
          });

          if (errors) throw errors;

          localStorage.setItem('token', data?.sign_in?.access_token!);

          // Validate user is a holder of our NFT.
          if (data?.sign_in?.access_token) {
            Mixpanel.track('Login Successful', { address });
            if (pathname === '/') {
              replace('/create-drop/claimable/erc20');
            }
          } else {
            Mixpanel.track('Login Unsuccessful');
            showNotification({
              title: 'Gate NFT not present on account',
              message:
                'Only collection NFT holders are allowed to access this portal.',
              color: 'red',
            });
            disconnect();
            replace('/');
          }
        }
      } catch (error: any) {
        console.log(error);
        setIsSigningMessage(false);
        showNotification({
          title: error?.[0]?.name ?? error?.name ?? 'Authentication Error',
          message:
            error?.[0]?.message ??
            error?.message ??
            'Failed to handle auth request',
          color: 'red',
        });
      }
    }

    authenticateWithSignature();
  }, [
    isConnected,
    nonce,
    address,
    disconnect,
    pathname,
    replace,
    signMessageAsync,
    loginMutation,
    isSigningMessage,
  ]);

  return isConnecting || isReconnecting ? (
    <AuthGateWrapper className={clsx(className)}>
      <LoadingOverlay overlayOpacity={1} visible={true} />
    </AuthGateWrapper>
  ) : (
    <></>
  );
};

export default withApollo(memo(AuthGate), {});
