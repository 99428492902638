import { css, createGlobalStyle } from 'styled-components';

import '@rainbow-me/rainbowkit/styles.css';

import { cssVariables } from './cssVariables';
import { headingStyles } from './headingStyles';

const globalCss = css`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* user-select: none; */
    -webkit-touch-callout: none; /* disable callout, image save panel (popup) */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent; /* "turn off" link highlight */
    /* transition: background-color 0.1s ease, color 0.1s ease, border 0.1s ease; */
  }
  body {
    box-sizing: border-box;
    font-family: var(--primary-font-family), -apple-system, BlinkMacSystemFont,
      'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    background: var(--main-background-color);
    color: var(--main-text-color);
    overflow-x: hidden;
    /* transition: all 0.4s ease-in; */
  }
  /* iframe {
    display: none;
  } */
  h1,
  h2,
  h3,
  h4 {
    text-rendering: optimizeLegibility;
  }
  a {
    text-decoration: none;
  }
  a:focus {
    outline: 0;
  }
`;

const GlobalStyles = createGlobalStyle`
  ${cssVariables};
  ${headingStyles};
  ${globalCss};
`;

export { GlobalStyles };
