import mixpanel, { Dict } from 'mixpanel-browser';

const isProd = process.env.NODE_ENV === 'production';
const deployUrl = 'https://beta.airdropr.com';

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN as string, {
  debug: true,
  ignore_dnt: true,
  ...(isProd && {
    api_host: `${deployUrl}/mixpanel`,
  }),
});

const actions = {
  identify: (id?: string) => {
    if (isProd) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (isProd) mixpanel.alias(id);
  },
  track: (name: string, props?: Dict) => {
    if (isProd) mixpanel.track(name, props);
  },
  people: {
    set: (props: Dict) => {
      if (isProd) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
