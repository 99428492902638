import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import clsx from 'clsx';
import {
  Plus,
  Forms,
  LayoutDashboard,
  ListDetails,
  Eye,
} from 'tabler-icons-react';
import {
  ThemeIcon,
  UnstyledButton,
  Group,
  Text,
  Button,
  Divider,
} from '@mantine/core';

interface MainLinkProps {
  className?: string;
  icon: React.ReactNode;
  color: string;
  label: string;
}

function MainLink({ className, icon, color, label }: MainLinkProps) {
  return (
    <UnstyledButton
      className={className}
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color:
          theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

        '&.active': {
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.blue[2],
        },

        '&:hover': {
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.blue[1],
        },
      })}
    >
      <Group>
        <ThemeIcon color={color} variant="light">
          {icon}
        </ThemeIcon>

        <Text size="sm">{label}</Text>
      </Group>
    </UnstyledButton>
  );
}

const MainLinksWrapper = styled.div``;

interface MainLinksProps {
  className?: string;
}

const MainLinks = ({ className }: MainLinksProps): JSX.Element => {
  const router = useRouter();

  return (
    <MainLinksWrapper className={clsx(className)}>
      {/* <Button fullWidth leftIcon={<Plus />} mb={24}>
        Create New
      </Button> */}
      <Divider my="xs" label="Dashboards" size="sm" />
      <Link href={'/dashboards'}>
        <MainLink
          className={clsx({ active: router.pathname === '/dashboards' })}
          icon={<LayoutDashboard size={16} />}
          color="blue"
          label="Dashboard"
          key={'Dashboard'}
        />
      </Link>

      <Divider my="xs" label="Claimable Drops" size="sm" />
      <div>
        <Link href={'/create-drop/claimable/erc20'}>
          <MainLink
            className={clsx({
              active: router.pathname === '/create-drop/claimable/erc20',
            })}
            icon={<Forms size={16} />}
            color="grape"
            label="ERC-20 Airdrop"
            key={'ERC-20 Airdrop'}
          />
        </Link>
        {/* <Link href={'/create-drop/claimable/erc721'}>

            <MainLink
              className={clsx({
                active: router.pathname === '/create-drop/claimable/erc721',
              })}
              icon={<Forms size={16} />}
              color="green"
              label="ERC-721 Airdrop"
              key={'ERC-721 Airdrop'}
            />

        </Link> */}
        <Link href={'/create-drop/claimable/erc1155'}>
          <MainLink
            className={clsx({
              active: router.pathname === '/create-drop/claimable/erc1155',
            })}
            icon={<Forms size={16} />}
            color="green"
            label="ERC-1155 Airdrop"
            key={'ERC-1155 Airdrop'}
          />
        </Link>
      </div>

      <Divider my="xs" label="Non-Claimable Drops" size="sm" />
      <div>
        <Link href={'/create-drop/non-claimable/erc20'}>
          <MainLink
            className={clsx({
              active: router.pathname === '/create-drop/non-claimable/erc20',
            })}
            icon={<Forms size={16} />}
            color="grape"
            label="ERC-20 Airdrop"
            key={'ERC-20 Airdrop'}
          />
        </Link>
        <Link href={'/create-drop/non-claimable/erc721'}>
          <MainLink
            className={clsx({
              active: router.pathname === '/create-drop/non-claimable/erc721',
            })}
            icon={<Forms size={16} />}
            color="green"
            label="ERC-721 Airdrop"
            key={'ERC-721 Airdrop'}
          />
        </Link>
        <Link href={'/create-drop/non-claimable/erc1155'}>
          <MainLink
            className={clsx({
              active: router.pathname === '/create-drop/non-claimable/erc1155',
            })}
            icon={<Forms size={16} />}
            color="green"
            label="ERC-1155 Airdrop"
            key={'ERC-1155 Airdrop'}
          />
        </Link>
      </div>

      <Divider my="xs" label="Wallet Lists" size="sm" />
      <Link href={'/lists/wallets'}>
        <MainLink
          className={clsx({ active: router.pathname === '/lists/wallets' })}
          icon={<ListDetails size={16} />}
          color="red"
          label="Wallet Lists"
          key={'Wallet Lists'}
        />
      </Link>

      <Divider my="xs" label="Snapshots" size="sm" />
      <Link href={'/snapshots'}>
        <MainLink
          className={clsx({ active: router.pathname === '/snapshots' })}
          icon={<Eye size={16} />}
          color="pink"
          label="Snapshots"
          key={'Snapshots'}
        />
      </Link>
    </MainLinksWrapper>
  );
};

export default MainLinks;
