import * as React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { AppShell, Navbar, Header, Group } from '@mantine/core';
import Image from 'next/image';
import { ConnectButton } from '@rainbow-me/rainbowkit';

import MainLinks from './MainLinks';
// import User from './User';

const AdminLayoutWrapper = styled.main`
  width: 100%;
  height: 100vh;
`;

interface AdminLayoutProps {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}

const AdminLayout = ({
  className,
  children,
}: AdminLayoutProps): JSX.Element => {
  return (
    <AdminLayoutWrapper className={clsx(className)}>
      <AppShell
        padding="xl"
        fixed
        navbar={
          <Navbar
            width={{ base: 250 }}
            height={'calc(100vh - 60px)'}
            p="xl"
            fixed
            style={{ background: '#F9FAFB' }}
          >
            <Navbar.Section grow mt="xs">
              <MainLinks />
            </Navbar.Section>
            {/* <Navbar.Section>
              <User />
            </Navbar.Section> */}
          </Navbar>
        }
        header={
          <Header height={60} fixed>
            <Group sx={{ height: '100%' }} px={60} position="apart" noWrap>
              <Image
                src={'/logos/airdropr-coloured.svg'}
                alt="Airdropr Logo"
                width={150}
                height={25}
                priority
              />
              <ConnectButton />
            </Group>
          </Header>
        }
      >
        {children}
      </AppShell>
    </AdminLayoutWrapper>
  );
};

export default AdminLayout;
