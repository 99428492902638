import {
  getDefaultWallets,
  connectorsForWallets,
} from '@rainbow-me/rainbowkit';
import { chain, createClient, configureChains, Client } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

const { polygon, polygonMumbai, mainnet, goerli } = chain;

const defaultChains = [polygon, polygonMumbai, mainnet, goerli];

const isMainnet = process.env.NEXT_PUBLIC_MAINNET ?? false;

const { chains, provider, webSocketProvider } = configureChains(
  defaultChains.filter((chain) => (isMainnet ? !chain.testnet : chain.testnet)),
  [
    alchemyProvider({
      apiKey:
        'IaN5CL-DNB0OYweDiLcxvclp9nzPtqqc' ||
        '_gg7wSSi0KMBsdKnGVfHDueq6xMB9EkC',
    }),
    publicProvider(),
  ]
);

const { wallets } = getDefaultWallets({
  appName: 'Airdropr',
  chains,
});

export const appInfo = {
  appName: 'Airdropr',
};

const connectors = connectorsForWallets([...wallets]);

export const configuredChains = chains;

export const wagmiClient: Client<any, any> = createClient({
  autoConnect: false,
  connectors,
  provider,
  webSocketProvider,
});
